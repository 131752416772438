import {
    selectSearchedMembersData,
    getSearchedMembersDataStatus,
    setSelectedMemberData,
    selectSelectedMemberData,
    getSelectedMemberDataStatus,
    setSelectedMemberDataStatus,
} from "../../../redux/slice/membershipSlice";
import {Button, Card, CardBody, Chip, DatePicker, Divider, Input, User} from "@nextui-org/react";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectAllUserData} from "../../../redux/slice/userSlice";
import {SearchIcon} from "../../../Icons/SearchIcon";
import {toast, ToastContainer} from "react-toastify";
import {MEMBERSHIP_STATUS_FOR_DESIGNATIONS, toastOptions} from "../../../../constants/WebPageConstants";
import {getMembershipDataById, searchMember} from "../../../../services/MembershipService";

export default function SearchMember(props) {

    const user = useSelector(selectAllUserData);
    const searchedMembersData = useSelector(selectSearchedMembersData);
    const searchedMembersDataStatus = useSelector(getSearchedMembersDataStatus);
    const dispatch = useDispatch();

    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');

    const [searchByDOB, setSearchByDOB] = React.useState();

    const ColorMap_MembershipStatus = {
        Active: "success",
        Guest: "secondary",
        Alumni: "warning",
        Left: "default",
        Terminated: "danger"
    };

    useEffect(() => {
        dispatch(setSelectedMemberData(null));
        dispatch(setSelectedMemberDataStatus("idle"))
    }, []);

    function goToNextPageWithSearchedData() {
        let data = {
            basicData: {
                name: (firstName + " " + lastName).trim().replace("&nbsp;", " "),
                dateOfBirth: searchByDOB.toString().split("T")[0]
            }
        }
        dispatch(setSelectedMemberData(data));
        props.nextPage();
    }

    function searchedMembersCardBehaviour(item) {
        if (MEMBERSHIP_STATUS_FOR_DESIGNATIONS.includes(item.membershipStatus))
            toast.error(<>
                <p className="text-medium">Selection Not Allowed</p>
                <p className="text-small">Membership is already {item.membershipStatus} member
                    in other club</p>
            </>, toastOptions);
        else {
            getMembershipDataById(dispatch, user, item.membershipId).then(
                () => props.nextPage()
            );
        }
    }

    return (
        <div className="flex flex-col ml-8 mr-8">
            <ToastContainer/>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-12 gap-y-4 mt-10">
                <Input
                    isRequired
                    type="text"
                    variant="underlined"
                    value={firstName}
                    label="First name"
                    onValueChange={setFirstName}
                />

                <Input
                    type="text"
                    variant="underlined"
                    value={lastName}
                    label="Last name"
                    onValueChange={setLastName}
                />

                <DatePicker
                    isRequired
                    variant="underlined"
                    label="Date Of Birth"
                    showMonthAndYearPickers
                    value={searchByDOB}
                    onChange={setSearchByDOB}
                />
            </div>
            <div className="flex gap-4 mt-8">
                <div>
                    <Button color="secondary" variant="solid"
                            isDisabled={firstName === '' && (undefined === searchByDOB || null === searchByDOB)}
                            onClick={() => searchMember(dispatch, user, firstName + " " + lastName, searchByDOB)}
                            startContent={searchedMembersDataStatus !== 'pending' ? <SearchIcon/> : null}
                            isLoading={searchedMembersDataStatus === 'pending'}>
                        Search
                    </Button>
                </div>
                {searchedMembersDataStatus === 'complete' && <div>
                    <Button color="success" variant="ghost"
                            isDisabled={firstName === null && searchByDOB === null}
                            onClick={() => goToNextPageWithSearchedData()}>
                        Advance
                    </Button>
                </div>}
                <div>
                    <Button color="default" variant="bordered" onClick={() => props.closeMemberRegistration()}>
                        Cancel
                    </Button>
                </div>
            </div>
            {(searchedMembersData.length > 0 && searchedMembersDataStatus === 'complete') &&
                <div className="mt-10">
                    {searchedMembersData.map((item, index) => (
                        <Card shadow="sm" key={index} isPressable
                              onPress={() => searchedMembersCardBehaviour(item)}>
                            <CardBody className="flex flex-col gap-2">
                                <User
                                    name={item.memberName}
                                    description={item.email}
                                    avatarProps={{
                                        src: item.profileImageURL
                                    }}
                                />
                                <Divider className="my-2"/>
                                <div className="flex justify-around h-5 space-x-4 text-small">
                                    <div>{new Date(item.dateOfBirth).toDateString()}</div>
                                    <Divider orientation="vertical"/>
                                    <Chip className="capitalize" variant="flat" size="sm"
                                          color={ColorMap_MembershipStatus[item.membershipStatus]}>
                                        {item.membershipStatus}
                                    </Chip>
                                </div>
                            </CardBody>
                        </Card>
                    ))}
                </div>
            }
        </div>
    );
}