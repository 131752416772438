import {
    Button,
    Card,
    CardBody,
    CardFooter,
    DatePicker, Modal,
    ModalBody,
    ModalContent, ModalFooter,
    ModalHeader,
    Select,
    SelectItem
} from "@nextui-org/react";
import {DD_MONTHS} from "../../../constants/dropdownList";
import {Add, Minus} from "../../Icons/SystemIcons";
import React, {useState} from "react";
import {getLocalTimeZone, now} from "@internationalized/date";
import {toast} from "react-toastify";
import {toastOptions} from "../../../constants/WebPageConstants";
import {useDispatch, useSelector} from "react-redux";
import {getUpdateReportDeadlinesDataStatus, selectRIDData} from "../../redux/slice/ridistrictSlice";
import {convertReportDeadlineDate} from "./DistrictAdmin";
import {selectUpdateRecordStatus} from "../../redux/slice/financeSlice";
import {updateHomeDistrictDeadlinesData} from "../../../services/RIDistrictServices";
import {selectAllUserData} from "../../redux/slice/userSlice";

export default function ModalReportDeadline(props) {
    const user = useSelector(selectAllUserData);
    const dispatch = useDispatch();
    const ridData = useSelector(selectRIDData);
    const updateReportDeadlinesDataStatus = useSelector(getUpdateReportDeadlinesDataStatus);

    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
    const [deadlinesReports, setDeadlinesReports] = useState(ridData?.reportDeadline ? [...convertReportDeadlineDate(ridData.reportDeadline)] : []);

    const addFields = () => {
        setDeadlinesReports([...deadlinesReports, {month: "", deadline: now(getLocalTimeZone())}])
        // else
        //     setDeadlinesAwards([...deadlinesAwards, {
        //         nominationType: "",
        //         startDate: now(getLocalTimeZone()),
        //         endDate: now(getLocalTimeZone())
        //     }]);
    }

    const removeFormFields = (i) => {
        let updatedValues = deadlinesReports;
        updatedValues.splice(i, 1);
        setDeadlinesReports([...updatedValues]);
    }

    const addReportingMonth = (i, e) => {
        let newFormValues = [...deadlinesReports];
        newFormValues[i].month = e.target.value;
        setDeadlinesReports(newFormValues);
        if (!checkReportsMonthsUniqueness()) {
            toast.error(<>
                <p className="text-medium">{"No Same Month Allowed"}</p>
                <p className="text-small">{"2 or more deadlines for the same month found or are empty"}</p>
            </>, toastOptions)
        }
    }

    const addReportingDeadline = (i, e) => {
        let newFormValues = [...deadlinesReports];
        newFormValues[i].deadline = e;
        setDeadlinesReports(newFormValues);
    }

    const checkReportsMonthsUniqueness = () => {
        let Months = new Set(deadlinesReports.map(item => item.month));
        let isSame = [...Months].length === deadlinesReports.length
        isSame ? setIsSubmitDisabled(false) : setIsSubmitDisabled(true);
        return isSame;
    }

    function submitReportingDeadlines(onClose) {
        let newDeadlines = [];
        deadlinesReports.forEach(value => {
            let newMonthValue = {...value};
            newMonthValue.deadline = value.deadline.toString().split(".")[0].split("+")[0];
            newDeadlines.push(newMonthValue);
        });

        updateHomeDistrictDeadlinesData(dispatch, user, newDeadlines);
    }

    return (
        <Modal
            isOpen={props.modal1.isOpen}
            size={"full"}
            placement="auto"
            onOpenChange={props.modal1.onOpenChange}
        >
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader
                            className="flex flex-col gap-1">Update Report Deadlines
                        </ModalHeader>
                        <ModalBody>
                            <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                                {deadlinesReports.map((forReporting, index) => (
                                    <Card key={index}>
                                        <CardBody>
                                            <Select
                                                isRequired
                                                label="Reporting Month"
                                                defaultSelectedKeys={[forReporting.month]}
                                                variant="underlined"
                                                // className="max-w-md col-span-6"
                                                onChange={e => addReportingMonth(index, e)}
                                            >
                                                {DD_MONTHS.map(data =>
                                                    <SelectItem key={data.value} value={data.value}>
                                                        {data.label}
                                                    </SelectItem>
                                                )}
                                            </Select>
                                            <DatePicker
                                                label="Deadline"
                                                description={"Date & Time Format : mm/dd/yyyy - hh:mm."}
                                                // className="max-w-md col-span-6"
                                                variant="underlined"
                                                granularity="second"
                                                hideTimeZone
                                                value={forReporting.deadline}
                                                onChange={e => addReportingDeadline(index, e)}
                                                showMonthAndYearPickers
                                            />
                                        </CardBody>
                                        <CardFooter>
                                            {deadlinesReports.length > 1
                                                ? <Button isIconOnlytype="button" color="danger" variant="flat"
                                                          className="w-auto col-span-1 sm:col-span-1 md:order-4"
                                                          onClick={() => removeFormFields(index)}>
                                                    <Minus fill="currentColor"
                                                           size={28}/>
                                                </Button>
                                                : null}
                                        </CardFooter>
                                    </Card>
                                ))}
                            </div>
                            <div className="mt-10 w-full flex justify-center">
                                <Button className="max-w-sm" color="primary" variant="bordered" startContent={
                                    <Add fill="currentColor" size={28}/>
                                } onClick={addFields}>
                                    Add more
                                </Button>
                            </div>

                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={onClose}>
                                Close
                            </Button>
                            <Button color="primary" isDisabled={isSubmitDisabled}
                                    isLoading={updateReportDeadlinesDataStatus === 'pending'}
                                    onPress={() => {
                                        submitReportingDeadlines();
                                    }}>
                                Submit
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}