import {createSlice} from '@reduxjs/toolkit';

const initialAarasState = {
    nominations: [],
    status: 'idle',
    editData: null,
    indexForEdit: null,
    statusOfIN: 'idle',
    projectNominationsCount: 0,
    projectNominationsList: []
}

const aarasSlice = createSlice({
    name: "aara",
    initialState: initialAarasState,
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload;
        },
        setAaras: (state, action) => {
            state.nominations = action.payload;
        },
        setProjectNominationsList: (state, action) => {
            state.projectNominationsList = action.payload;
        },
        setProjectNominationsCount: (state, action) => {
            state.projectNominationsCount = action.payload;
        },
        addToProjectNominationsList: (state, action) => {
            state.projectNominationsCount++;
            state.projectNominationsList.push(action.payload);
        },
        updateProjectNominationsList: (state, action) => {
            let index = state.projectNominationsList.findIndex(data => data["id"] === action.payload)
            state.projectNominationsList[index]["isRemoved"] = true;
        },
        addAaraNominations: (state, action) => {
            state.nominations.push([action.payload]);
        },
        updateAaraNominations: (state, action) => {
            state.nominations[state.indexForEdit] = action.payload;
        },
        setDataForEdit: (state, action) => {
            state.editData = action.payload;
        },
        setIndexForEdit: (state, action) => {
            state.indexForEdit = action.payload;
        },
        setStatusOfIN: (state, action) => {
            state.statusOfIN = action.payload;
        },
        setUpdatedIndividualData: (state, action) => {
            state.editData.individualNominationList = action.payload;
        },
        setAllAaraDataNull: (state) => {
            state.nominations = [];
            state.status = 'idle';
            state.editData = null;
            state.indexForEdit = null;
            state.statusOfIN = 'idle';
            state.projectNominationsCount = 0;
            state.projectNominationsList = [];
        }
    }
});
export const {
    setStatus,
    setAaras,
    setProjectNominationsList,
    setProjectNominationsCount,
    addToProjectNominationsList,
    updateProjectNominationsList,
    addAaraNominations,
    updateAaraNominations,
    setDataForEdit,
    setIndexForEdit,
    setAllAaraDataNull,
    setStatusOfIN,
    setUpdatedIndividualData
} = aarasSlice.actions;

export const selectAllNominations = (state) => state.aara.nominations;
export const selectDataForEdit = (state) => state.aara.editData;
export const selectFetchStatus = (state) => state.aara.status
export const getIndexForEdit = (state) => state.aara.indexForEdit;
export const getStatusOfIN = (state) => state.aara.statusOfIN;

export const selectProjectNominationsList = (state) => state.aara.projectNominationsList;
export const getProjectNominationsCount = (state) => state.aara.projectNominationsCount;


export default aarasSlice.reducer;