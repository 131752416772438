import React from 'react'
import {useSelector} from "react-redux"
import {Navigate, useLocation} from "react-router-dom"
import {selectAllUserData} from "../redux/slice/userSlice";

const ProtectedRoute = ({children}) => {
    const user = useSelector(selectAllUserData);

    let location = useLocation();

    if (null === user.emailId) {
        return <Navigate to="/" state={{from: location}} replace/>
    }
    return children

};

export default ProtectedRoute;
