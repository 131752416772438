import BundledEditor from "../../BundledEditor";
import React from "react";

export function applicationRTESection(positionsTillDate, handleUpdate, handleBeforeAddUndo,
                                      title, isRequired, description) {
    return (
        <div>
            <h2 className="mt-10 text-lg text-gray-900">&nbsp;{title}
                {isRequired === true && <span className="text-danger"> *</span>}
            </h2>
            {description !== null && <p className="mb-2 italic text-sm text-gray-600">&nbsp;{description}</p>}
            <BundledEditor
                name="groundWork"
                value={positionsTillDate}
                onEditorChange={handleUpdate}
                onBeforeAddUndo={handleBeforeAddUndo}
                // onPaste={handlePasteForGroundWork}
                init={{
                    height: 300,
                    menubar: true,
                    paste_data_images: false,
                    invalid_elements: "img,video,picture",
                    plugins: ["wordcount"],
                    toolbar:
                        "undo redo | blocks | paste |" +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help | common",
                    content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
            />
        </div>
    )
}