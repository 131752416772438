import React from "react";
import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Input,
    Button,
    DropdownTrigger,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Chip,
    Pagination, useDisclosure, ModalContent, Modal, Spinner, Select, SelectItem, Checkbox,
} from "@nextui-org/react";
import {SearchIcon} from "../../Icons/SearchIcon";
import {
    COLUMNS_FINANCE
} from "../../../constants/tableColumns";
import {capitalize} from "./utils";
import {ChevronDownIcon} from "../../Icons/ChevronDownIcon";
import TransactionDetails from "./TransactionDetails";
import {useDispatch, useSelector} from "react-redux";
import {
    selectAllFinanceRecords,
    selectRecordsFetchStatus
} from "../../redux/slice/financeSlice";
import {OPTIONS_TRANSACTION_TYPES} from "../../../constants/tableFilterOptions";
import {ONLY_PRES_SEC} from "../../../constants/WebPageConstants";
import {selectClubsList} from "../../redux/slice/clubDataSlice";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {getFinancialRecordsByTransactionId} from "../../../services/FinanceServices";

const ColorMap_TransactionType = {
    Credit: "success",
    Debit: "danger",
};

export default function TransactionsTable() {
    const user = useSelector(selectAllUserData);
    const clubsList = useSelector(selectClubsList);
    const financeRecords = useSelector(selectAllFinanceRecords);
    const fetchFinanceStatus = useSelector(selectRecordsFetchStatus);
    const dispatch = useDispatch();

    const {isOpen, onOpen, onOpenChange} = useDisclosure();

    const INITIAL_VISIBLE_COLUMNS = ["transactionId", "clubName", "date", "paymentStatus", "amount", "transactionType"];
    const [filterValue, setFilterValue] = React.useState("");
    const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
    const [visibleColumns, setVisibleColumns] = React.useState(new Set(INITIAL_VISIBLE_COLUMNS));
    const [isSelected, setIsSelected] = React.useState(true);
    const [clubNameFilter, setClubNameFilter] = React.useState("all");
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [sortDescriptor, setSortDescriptor] = React.useState({
        column: "transactionId",
        direction: "descending",
    });

    const [transactionTypeFilter, setTransactionTypeFilter] = React.useState("all");
    // const [transactionData, setTransactionData] = React.useState(null);

    const [page, setPage] = React.useState(1);

    const hasSearchFilter = Boolean(filterValue);

    const headerColumns = React.useMemo(() => {
        if (visibleColumns === "all") return COLUMNS_FINANCE;

        return COLUMNS_FINANCE.filter((column) => Array.from(visibleColumns).includes(column.uid));
    }, [visibleColumns]);

    const filteredItems = React.useMemo(() => {
        let filteredFinanceRecords = [...financeRecords];

        if (hasSearchFilter) {
            filteredFinanceRecords = filteredFinanceRecords.filter((records) =>
                records.transactionId.toLowerCase().includes(filterValue.toLowerCase()),
            );
        }
        if (transactionTypeFilter !== "all" && Array.from(transactionTypeFilter).length !== OPTIONS_TRANSACTION_TYPES.length) {
            filteredFinanceRecords = filteredFinanceRecords.filter((records) =>
                Array.from(transactionTypeFilter).includes(records.transactionType),
            );
        }
        if (clubNameFilter !== "all" && Array.from(clubNameFilter).length !== clubsList.length) {
            filteredFinanceRecords = filteredFinanceRecords.filter(records =>
                Array.from(clubNameFilter).includes(records.clubName),
            );
        }

        return filteredFinanceRecords;
    }, [financeRecords, filterValue, transactionTypeFilter, clubNameFilter]);


    const pages = Math.ceil(filteredItems.length / rowsPerPage);

    const items = React.useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);

    const sortedItems = React.useMemo(() => {
        return [...items].sort((a, b) => {
            const first = a[sortDescriptor.column];
            const second = b[sortDescriptor.column];
            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, items]);

    const renderCell = React.useCallback((record, columnKey) => {
        const cellValue = record[columnKey];

        switch (columnKey) {
            case "transactionType":
                return (
                    <Chip className="capitalize" color={ColorMap_TransactionType[record.transactionType]} size="sm"
                          variant="flat">
                        {cellValue}
                    </Chip>
                );
            case "timestamp":
                return (
                    <p className="text-bold text-small capitalize">{
                        new Date(record.timestamp.replaceAll("T", " ")).toLocaleString()}</p>
                );
            default:
                return cellValue;
        }
    }, []);

    const onNextPage = React.useCallback(() => {
        if (page < pages) {
            setPage(page + 1);
        }
    }, [page, pages]);

    const onPreviousPage = React.useCallback(() => {
        if (page > 1) {
            setPage(page - 1);
        }
    }, [page]);

    const onRowsPerPageChange = React.useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, []);

    const onSearchChange = React.useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = React.useCallback(() => {
        setFilterValue("")
        setPage(1)
    }, [])

    const topContent = React.useMemo(() => {
        return (
            <div className="flex flex-col gap-4">
                <div className="grid grid-cols-6 md:grid-cols-12 justify-between gap-3">
                    <Input
                        isClearable
                        className="col-span-6 md:col-span-4 w-full"
                        placeholder="Search by transaction id..."
                        startContent={<SearchIcon/>}
                        value={filterValue}
                        onClear={() => onClear()}
                        onValueChange={onSearchChange}
                    />
                    <div className="col-span-6 md:col-span-8 flex flex-wrap gap-3 justify-end">

                        <Dropdown>
                            <DropdownTrigger>
                                <Button endContent={<ChevronDownIcon className="text-small"/>} variant="flat">
                                    Transaction Type
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={transactionTypeFilter}
                                selectionMode="multiple"
                                onSelectionChange={setTransactionTypeFilter}>
                                {OPTIONS_TRANSACTION_TYPES.map((types) => (
                                    <DropdownItem key={types.uid} className="capitalize"
                                                  description={types.description} startContent={types.icon}>
                                        {capitalize(types.name)}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown>
                            <DropdownTrigger>
                                <Button endContent={<ChevronDownIcon className="text-small"/>} variant="flat">
                                    Columns
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={visibleColumns}
                                selectionMode="multiple"
                                onSelectionChange={setVisibleColumns}
                            >
                                {COLUMNS_FINANCE.map((column) => (
                                    <DropdownItem key={column.uid} className="capitalize">
                                        {capitalize(column.name)}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                    {!ONLY_PRES_SEC.includes(user.pranaliAccess) &&
                        <div className="col-span-full flex flex-wrap gap-4">
                            <Select
                                items={clubsList}
                                selectionMode="multiple"
                                label="Selected Club's"
                                placeholder="Select a club"
                                labelPlacement="inside"
                                selectedKeys={clubNameFilter}
                                onSelectionChange={setClubNameFilter}
                                className="max-w-xs sm:min-w-sm sm:max-w-md">
                                {clubDataListItems => (
                                    <SelectItem key={clubDataListItems.name} textValue={clubDataListItems.name}>
                                        <div className="flex gap-2 items-center">
                                            <div className="flex flex-col">
                                                <span className="text-small">{clubDataListItems.name}</span>
                                                <span
                                                    className="text-tiny text-default-400">Zone {clubDataListItems.zone}</span>
                                            </div>
                                        </div>
                                    </SelectItem>
                                )}
                            </Select>
                            <Checkbox isSelected={isSelected}
                                      onValueChange={(value) => {
                                          setIsSelected(value);
                                          if (!value)
                                              setClubNameFilter([]);
                                          else {
                                              let tempList = [];
                                              clubsList.forEach(club => tempList.push(club.name));
                                              setClubNameFilter(tempList);
                                          }
                                      }}> Select All Club</Checkbox>
                        </div>
                    }
                </div>
                <div className="flex justify-between items-center">
                    <span className="text-default-400 text-small">Total {financeRecords.length} records</span>
                    <label className="flex items-center text-default-400 text-small">
                        Rows per page:
                        <select
                            className="bg-transparent outline-none text-default-400 text-small"
                            onChange={onRowsPerPageChange}
                        >
                            <option value="15">15</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </select>
                    </label>
                </div>
            </div>
        );
    }, [
        filterValue,
        clubNameFilter,
        transactionTypeFilter,
        visibleColumns,
        onRowsPerPageChange,
        financeRecords.length,
        onSearchChange,
        hasSearchFilter,
    ]);

    const bottomContent = React.useMemo(() => {
        return (
            <div className="py-2 px-2 flex justify-between items-center">
                <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="primary"
                    page={page}
                    total={pages}
                    onChange={setPage}
                />
                <div className="hidden sm:flex w-[30%] justify-end gap-2">
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onPreviousPage}>
                        Previous
                    </Button>
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onNextPage}>
                        Next
                    </Button>
                </div>
            </div>
        );
    }, [selectedKeys, items.length, page, pages, hasSearchFilter]);

    return (
        <>
            <Table
                aria-label="Example table with custom cells, pagination and sorting"
                classNames={{
                    wrapper: "min-h-[500px]",
                }}
                isHeaderSticky
                bottomContent={bottomContent}
                bottomContentPlacement="outside"
                selectedKeys={selectedKeys}
                sortDescriptor={sortDescriptor}
                topContent={topContent}
                topContentPlacement="outside"
                onSelectionChange={setSelectedKeys}
                onSortChange={setSortDescriptor}
                onRowAction={key => getFinancialRecordsByTransactionId(key, user, dispatch)}>
                <TableHeader columns={headerColumns}>
                    {column => (
                        <TableColumn key={column.uid} align={column.uid === "actions" ? "center" : "start"}
                                     allowsSorting={column.sortable}>
                            {column.name}
                        </TableColumn>
                    )}
                </TableHeader>
                <TableBody
                    emptyContent={fetchFinanceStatus === "complete" ? "No record found" : null}
                    isLoading={fetchFinanceStatus === 'pending'} items={sortedItems}
                    loadingContent={fetchFinanceStatus !== "complete" ? <Spinner label="Loading..."/> : null}>
                    {item => (
                        <TableRow key={item.transactionId} onClick={onOpen}>
                            {columnKey => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            <Modal
                isOpen={isOpen}
                backdrop="blur"
                placement="auto"
                size="xl"
                onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <TransactionDetails onClose={onClose} records={financeRecords}/>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}