import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Checkbox, Chip,
    Progress,
    Select,
    SelectItem,
    Table, TableBody, TableCell, TableColumn, TableHeader, TableRow
} from "@nextui-org/react";
import {NOMINATION_STATUS_DRAFT, NOMINATION_TYPE_YEAR_END, toastOptions} from "../../../constants/WebPageConstants";
import React, {useEffect, useState} from "react";
import {DD_AVENUES_LIST, DD_NOMINATION_TYPES} from "../../../constants/dropdownList";
import {Add} from "../../Icons/SystemIcons";
import ApiEndpoint from "../../../services/ApiEndpoint";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {
    addAaraNominations,
    addToProjectNominationsList,
    getIndexForEdit,
    getProjectNominationsCount,
    selectDataForEdit,
    selectProjectNominationsList,
    setDataForEdit,
    setIndexForEdit, setProjectNominationsCount,
    setProjectNominationsList,
    updateAaraNominations,
    updateProjectNominationsList
} from "../../redux/slice/aaraSlice";
import {toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {DeleteIcon} from "../../Icons/Icons";

export default function NewNominationForProjects() {
    const user = useSelector(selectAllUserData);
    const editData = useSelector(selectDataForEdit);
    const indexForEdit = useSelector(getIndexForEdit);
    const nominations = useSelector(selectProjectNominationsList);
    const nominationsCount = useSelector(getProjectNominationsCount);

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const apiEndPoint = new ApiEndpoint();

    const [isFetchingProjects, setIsFetchingProjects] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
    const [isSubmittingNomination, setIsSubmittingNomination] = useState(false);

    const [projectId, setProjectId] = useState(new Set([]));
    const [nominationAvenue, setNominationAvenue] = useState(new Set([]));

    const [fetchedProjects, setFetchedProjects] = useState([]);
    const [isDraftNomination, setIsDraftNomination] = useState(
        editData?.adminData?.status === NOMINATION_STATUS_DRAFT);
    const [nominationType, setNominationType] = useState(null != editData?.nominationType
        ? new Set([editData.nominationType]) : new Set([]));


    const [quarterlyData, setQuarterlyData] = useState([]);

    const columns = [
        {
            key: "projectId",
            label: "PROJECT ID",
        },
        {
            key: "projectName",
            label: "PROJECT NAME",
        },
        {
            key: "nominationFor",
            label: "NOMINATION FOR",
        },
        {
            key: "isRemoved",
            label: "ACTION",
        },
    ];

    const renderCell = React.useCallback((item, columnKey) => {
        const cellValue = item[columnKey];

        switch (columnKey) {
            case "isRemoved":
                return (
                    !cellValue
                        ? <Chip variant="bordered" color="danger" endContent={<DeleteIcon size={18}/>}
                                onClick={() => {
                                    dispatch(updateProjectNominationsList(item["id"]));
                                    if (isSubmitDisabled)
                                        setIsSubmitDisabled(false);
                                }}>
                            Remove
                        </Chip>
                        : <Chip isDisabled variant="faded" color="default">
                            Removed
                        </Chip>
                );
            default:
                return cellValue;
        }
    }, []);

    const updateQuarterlyData = (e) => {
        setNominationType(new Set([e.target.value]));
        dispatch(setProjectNominationsList([]));
        if (NOMINATION_TYPE_YEAR_END.includes(e.target.value))
            setQuarterlyData(fetchedProjects)
        else
            setQuarterlyData(fetchedProjects.filter(project => project["reportingQuarter"] === e.target.value));
    }

    const addDataToList = () => {
        let tempData = {
            id: nominationsCount,
            projectId: projectId.keys().next().value,
            projectName: quarterlyData.find(project => project.projectId === projectId.keys().next().value).name,
            nominationFor: nominationAvenue.keys().next().value,
            isRemoved: false
        }

        dispatch(addToProjectNominationsList(tempData));
        setProjectId(new Set([]));
        setNominationAvenue(new Set([]));
    }


    async function submitForm(e) {
        e.preventDefault();
        setIsSubmittingNomination(!isSubmittingNomination);

        let aaraData = {
            nominationType: nominationType.keys().next().value,
            isDraftRequest: isDraftNomination,
            projectNominations: nominations.filter(data => !data.isRemoved)
        }

        if (!checkProjectUniqueness()) {
            toast.error(<>
                <p className="text-medium">{"No Same Nominations Allowed"}</p>
                <p className="text-small">{"2 or more nominations for the same project found"}</p>
            </>, toastOptions);
        } else if (!checkProjectsLengthIsAtLeastOne()) {
            toast.error(<>
                <p className="text-medium">{"No Nominations Found "}</p>
                <p className="text-small">{"At-least 1 project nomination needs to be added."}</p>
            </>, toastOptions);
        } else {
            if (null === indexForEdit) {
                await reportNewAara(aaraData);
            } else {
                await updateExistingAara(aaraData);
            }
        }
        // In Case of Error
        setIsSubmittingNomination(false);
    }

    function cancelForm() {
        dispatch(setIndexForEdit(null));
        dispatch(setDataForEdit(null));
        dispatch(setProjectNominationsCount(0));
        dispatch(setProjectNominationsList([]));
        navigate("/pranali/aara")
    }

    function reportNewAara(aaraData) {
        return axios(apiEndPoint.addAaraNominations(user, aaraData))
            .then(re => {
                    if (re.status === 201) {
                        toast.success(<>
                            <p className="text-medium">{aaraData.isDraftRequest ? "Nomination Saved Successfully" : "Nomination Submitted Successfully"}</p>
                            <p className="text-small">{"Nomination id allotted : " + re.data.nominationId + ". This page will be closing soon"}</p>
                        </>, toastOptions);
                        dispatch(addAaraNominations(re.data));
                        setIsSubmittingNomination(false);
                        setTimeout(cancelForm, 3000);
                    }
                }
            )
            .catch(e => {
                setIsSubmittingNomination(false);
                throwErrorToast(e);
            });
    }

    function updateExistingAara(aaraData) {
        aaraData["nominationId"] = editData.nominationId;
        return axios(apiEndPoint.updateAaraNominations(user, aaraData))
            .then(re => {
                    if (re.status === 202) {
                        toast.success(<>
                            <p className="text-medium">{aaraData.isDraftRequest ? "Nomination " + aaraData.nominationId + " Updated Successfully" : "Nomination " + aaraData.nominationId + " Submitted Successfully"}</p>
                            <p className="text-small">{"This page will be closing soon"}</p>
                        </>, toastOptions)
                        dispatch(updateAaraNominations(re.data));
                        setIsSubmittingNomination(false);
                        setTimeout(cancelForm, 3000);
                    }
                }
            )
            .catch(e => {
                setIsSubmittingNomination(false);
                throwErrorToast(e);
            });
    }

    function getProjects() {
        setIsFetchingProjects(true);
        return axios(apiEndPoint.getProjectsList(user, true)).then(res => {
            setIsFetchingProjects(false);
            setFetchedProjects(res.data);
            if (null != editData && null !== editData.projectNominations) {
                setQuarterlyData(res.data.filter(
                    project => project["reportingQuarter"] === editData.nominationType
                ));
            }
            return res;
        }).catch(e => {
            throwErrorToast(e);
            setIsFetchingProjects(false);
        });
    }

    const checkProjectUniqueness = () => {
        let filteredData = nominations.filter(data => !data.isRemoved);
        let IDs = new Set(filteredData.map(item => item.projectId));
        let isSame = [...IDs].length === filteredData.length
        isSame ? setIsSubmitDisabled(false) : setIsSubmitDisabled(true);
        return isSame;
    }

    const checkProjectsLengthIsAtLeastOne = () => {
        let filteredData = nominations.filter(data => !data.isRemoved);
        return filteredData.length > 0;
    }

    useEffect(() => {
        getProjects()
    }, []);

    return (
        <>
            <ToastContainer/>
            <Card>
                <form onSubmit={submitForm}>
                    <CardBody>
                        {null !== indexForEdit
                            ? <h2 className="text-base font-semibold leading-7 text-gray-900">Update AARA Nomination :
                                <span className="text-sm text-gray-500"> {editData.nominationId}</span></h2>
                            : <h2 className="text-base font-semibold leading-7 text-gray-900">New AARA Nomination</h2>}
                        <div className="mt-10 w-full flex flex-col gap-4">
                            <Select
                                isRequired
                                labelPlacement="outside-left"
                                label="Nomination Type"
                                variant="underlined"
                                className="max-w-md"
                                disableAnimation={false}
                                selectedKeys={nominationType}
                                onSelectionChange={setNominationType}
                                onChange={updateQuarterlyData}
                            >
                                {DD_NOMINATION_TYPES.map((nominations) => (
                                    <SelectItem key={nominations.key} value={nominations.key}>
                                        {nominations.value}
                                    </SelectItem>
                                ))}
                            </Select>
                        </div>
                        <div className="mt-10 w-full flex flex-col gap-4">
                            <Checkbox isSelected={isDraftNomination} onValueChange={setIsDraftNomination}>
                                Save nomination as Draft
                            </Checkbox>
                        </div>
                        {isFetchingProjects && <div className="mt-10 w-full flex flex-col gap-4">
                            <Progress size="sm" isIndeterminate aria-label="Loading..."/>
                            <p className="text-sm font-regular leading-7 text-gray-900">
                                Please wait while we fetch projects for You
                            </p>
                        </div>}

                        {(quarterlyData && quarterlyData.length > 0 && !isFetchingProjects) &&
                            <>
                                <div className="mt-5 grid sm:grid-cols-3 gap-4 items-center">
                                    <Select
                                        label="Project Name"
                                        variant="underlined"
                                        className="max-w-md"
                                        selectedKeys={projectId}
                                        onSelectionChange={setProjectId}
                                    >
                                        {quarterlyData.map(data =>
                                            <SelectItem key={data.projectId} textValue={data.name}>
                                                <div className="flex gap-2 items-center">
                                                    <div className="flex flex-col">
                                                        <span className="text-small">{data.name}</span>
                                                        <span
                                                            className="text-tiny text-default-400">{data.projectId}</span>
                                                    </div>
                                                </div>
                                            </SelectItem>
                                        )}
                                    </Select>

                                    <Select
                                        label="Nomination For Avenue"
                                        variant="underlined"
                                        className="max-w-md"
                                        isDisabled={!projectId.keys().next().value}
                                        selectedKeys={nominationAvenue}
                                        onSelectionChange={setNominationAvenue}
                                    >
                                        {projectId.keys().next().value && DD_AVENUES_LIST.filter(avenues => {
                                            let qd = quarterlyData.find(data => data.projectId === projectId.keys().next().value);
                                            if (avenues.value === "Joint Project" && qd.isJointProject) {
                                                return true
                                            } else {
                                                return !qd.isJointProject && (avenues.value === qd.avenueMain || avenues.value === qd?.avenueOptional)
                                            }
                                        }).map(data => (
                                            <SelectItem key={data.value} textValue={data.value}>
                                                {data.label}
                                            </SelectItem>
                                        ))}
                                    </Select>
                                    <div>
                                        <Button type="button"
                                                className="text-secondary" color="secondary"
                                                variant="ghost" aria-label="Add project to nominations list"
                                                endContent={<Add fill="currentColor" size={24}/>}
                                                isDisabled={undefined === projectId.keys().next().value || undefined === nominationAvenue.keys().next().value}
                                                onClick={() => addDataToList()}>
                                            Add project to nominations list
                                        </Button>
                                    </div>
                                </div>

                                <Table className="mt-10" aria-label="Project Nominations Table">
                                    <TableHeader columns={columns}>
                                        {(column) =>
                                            <TableColumn key={column.key}
                                                         align={column.uid === "isRemoved" ? "center" : "start"}>
                                                {column.label}
                                            </TableColumn>}
                                    </TableHeader>
                                    <TableBody
                                        emptyContent={nominations.length === 0 ? "No record found" : null}
                                        items={nominations}
                                    >
                                        {item => (
                                            <TableRow key={item.id}>
                                                {columnKey => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </>
                        }
                        {(!nominationType.has('') && quarterlyData.length === 0 && !isFetchingProjects) &&
                            <div className="mt-10 mb-10 w-full flex flex-col  text-center content-center">

                                <p className="text-lg font-regular text-gray-500">No Records found</p>
                                <p className="text-md font-regular font-semibold text-gray-600">For the given Nomination
                                    Type</p>
                            </div>}
                    </CardBody>

                    <CardFooter className="flex flex-wrap gap-4 justify-end">
                        <Button type="button" color="default" variant="faded" onClick={() => cancelForm()}>
                            Cancel
                        </Button>
                        <Button type="submit" isDisabled={quarterlyData.length === 0 || isSubmitDisabled}
                                color="primary" variant="ghost"
                                isLoading={isSubmittingNomination}>
                            {isDraftNomination ? "Save as Draft" : "Submit"}
                        </Button>
                    </CardFooter>
                </form>
            </Card>
        </>
    );
}

export function throwErrorToast(e) {
    toast.error(<>
        <p className="text-medium">{null != e?.response?.data?.code ? e.response.data.code.replaceAll("_", " ") : "Internal Server Error"}</p>
        <p className="text-small">{null != e?.response?.data?.message ? e.response.data.message : "Please contact IT Team to report and resolve the error. Issue Timestamp : " + new Date().toLocaleString()}</p>
    </>, toastOptions)
}
