import Chart from "react-apexcharts";
import React from "react";

export default function Line_ClubWallet(prop) {

    const height = 90;
    const width = 175;

    const series = [{
        name: 'Club Wallet',
        data: prop.data
    }];
    const options = {
        chart: {
            height: 80,
            type: 'line',
            sparkline: {
                enabled: true
            },
        },
        sparkline: {
            enabled: true
        },
        dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 2,
            opacity: 0.2,
        },
        markers: {
            size: 0,
        },
        stroke: {
            width: 3,
            curve: 'smooth'
        },
        // grid: {
        //     padding: {
        //         left: 110
        //     }
        // },
        // fill: {
        //     type: 'gradient',
        //     gradient: {
        //         shade: 'dark',
        //         gradientToColors: ['#FDD835'],
        //         shadeIntensity: 1,
        //         type: 'horizontal',
        //         opacityFrom: 1,
        //         opacityTo: 1,
        //         stops: [0, 100, 100, 100]
        //     },
        // },
        tooltip: {
            theme: 'dark',
            shared: false,
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function formatter(val) {
                        return '';
                    }
                }
            }
        },
        colors: ['#fff']
    }

    return (
        <Chart
            options={options}
            series={series}
            type="line"
            width={width}
            height={height}
        />
    )
}