import {createSlice} from '@reduxjs/toolkit';

const initialReportsState = {
    projectsList: [],
    projectsStatus: 'idle',
    isProjectListForNomination: false,

    meetingsList: [],
    meetingsStatus: 'idle',

    ambassadorialList: [],
    ambassadorialStatus: 'idle',

    selectedRecord: null,
    selectedRecordType: null,
    selectedRecordStatus: 'idle',

    newReportStatus: 'idle',

    projectsForAmbassadorial: [],
    projectsForAmbassadorialStatus: 'idle',

    moMForAmbassadorial: [],
    moMForAmbassadorialStatus: 'idle',
}

const reportingSlice = createSlice({
    name: "reports",
    initialState: initialReportsState,
    reducers: {
        setProjects: (state, action) => {
            state.projectsList = action.payload;
        },
        setProjectsStatusAndIsNomination: (state, action) => {
            state.projectsStatus = action.payload.projectsStatus;
            state.isProjectListForNomination = action.payload.isForNominations;
        },

        setMinutesOfMeetings: (state, action) => {
            state.meetingsList = action.payload;
        },
        setMinutesOfMeetingsStatus: (state, action) => {
            state.meetingsStatus = action.payload;
        },

        setAmbassadorialReports: (state, action) => {
            state.ambassadorialList = action.payload;
        },
        setAmbassadorialReportsStatus: (state, action) => {
            state.ambassadorialStatus = action.payload;
        },

        setSelectedReport: (state, action) => {
            state.selectedRecord = action.payload.report;
            state.selectedRecordType = action.payload.reportType;
        },
        setSelectedReportStatus: (state, action) => {
            state.selectedRecordStatus = action.payload;
        },

        setNewReportStatus: (state, action) => {
            state.newReportStatus = action.payload;
        },

        setProjectsForAmbassadorial: (state, action) => {
            state.projectsForAmbassadorial = action.payload;
        },
        setProjectsForAmbassadorialStatus: (state, action) => {
            state.projectsForAmbassadorialStatus = action.payload;
        },

        setMoMForAmbassadorial: (state, action) => {
            state.moMForAmbassadorial = action.payload;
        },
        setMoMForAmbassadorialStatus: (state, action) => {
            state.moMForAmbassadorialStatus = action.payload;
        },

        setAllReportRecordsNull: (state) => {
            state.projectsList = [];
            state.projectsStatus = 'idle';
            state.isProjectListForNomination = false;

            state.meetingsList = [];
            state.meetingsStatus = 'idle';

            state.ambassadorialList = [];
            state.ambassadorialStatus = 'idle';

            state.selectedRecord = null;
            state.selectedRecordType = null;
            state.selectedRecordStatus = 'idle';

            state.newReportStatus = 'idle';

            state.projectsForAmbassadorial = [];
            state.projectsForAmbassadorialStatus = 'idle';

            state.moMForAmbassadorial = [];
            state.moMForAmbassadorialStatus = 'idle';
        }
    }
});
export const selectProjectList = (state) => state.reports.projectsList;
export const getProjectReportsStatus = (state) => state.reports.projectsStatus;
export const getIsProjectListForNomination = (state) => state.reports.isProjectListForNomination;

export const selectAllMeetingReports = (state) => state.reports.meetingsList;
export const getMeetingReportsStatus = (state) => state.reports.meetingsStatus;

export const selectAllAmbassadorialReports = (state) => state.reports.ambassadorialList;
export const getAmbassadorialReportsStatus = (state) => state.reports.ambassadorialStatus;

export const selectProjectsForAmbassadorial = (state) => state.reports.projectsForAmbassadorial;
export const getProjectsForAmbassadorialStatus = (state) => state.reports.projectsForAmbassadorialStatus;

export const selectMoMForAmbassadorial = (state) => state.reports.moMForAmbassadorial;
export const getMoMForAmbassadorialStatus = (state) => state.reports.moMForAmbassadorialStatus;

export const selectSelectedReport = (state) => state.reports.selectedRecord;
export const getSelectedReportStatus = (state) => state.reports.selectedRecordStatus;
export const getSelectedRecordType = (state) => state.reports.selectedRecordType;

export const getNewReportStatus = (state) => state.reports.newReportStatus;

export const {
    setProjects,
    setProjectsStatusAndIsNomination,

    setMinutesOfMeetings,
    setMinutesOfMeetingsStatus,

    setAmbassadorialReports,
    setAmbassadorialReportsStatus,

    setSelectedReport,
    setSelectedReportStatus,

    setNewReportStatus,

    setProjectsForAmbassadorial,
    setProjectsForAmbassadorialStatus,

    setMoMForAmbassadorial,
    setMoMForAmbassadorialStatus,

    setAllReportRecordsNull
} = reportingSlice.actions;


export default reportingSlice.reducer;