import {useDispatch, useSelector} from "react-redux";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {useNavigate} from "react-router-dom";
import {
    getAmbassadorialReportsStatus,
} from "../../redux/slice/reportingSlice";
import {ToastContainer} from "react-toastify";
import {Button} from "@nextui-org/react";
import {Add, Refresh} from "../../Icons/SystemIcons";
import React, {useEffect} from "react";
import AmbassadorialTable from "./AmbassadorialTable";
import {getAmbassadorial} from "../../../services/AmbassadorialServices";
import {ONLY_PRES_SEC_AND_IT} from "../../../constants/WebPageConstants";

export default function Ambassadorial() {
    const user = useSelector(selectAllUserData);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ambassadorialReportStatus = useSelector(getAmbassadorialReportsStatus);

    useEffect(() => {
        if (ambassadorialReportStatus === 'idle')
            getAmbassadorial(dispatch, user);
    }, []);

    return (
        <div className="flex flex-col gap-4 sm:gap-8">
            <ToastContainer/>
            <div className="flex justify-around gap-4 sm:gap-8 items-center">
                <div className="relative">
                    <h1 className="text-2xl sm:text-3xl font-medium">Ambassadorial</h1>
                </div>

                <div className="flex flex-col">
                    <div className="flex justify-end items-center">
                        {ONLY_PRES_SEC_AND_IT.includes(user.pranaliAccess) && <Button className="hidden sm:flex mr-2" type="button" color="success" variant="ghost"
                                 startContent={<Add fill="currentColor" size={28}/>}
                                 onClick={() => navigate("/pranali/ambassadorials/new-report")}>
                            New Ambassadorial Report
                        </Button>}
                        {ONLY_PRES_SEC_AND_IT.includes(user.pranaliAccess) && <Button className="mr-2 sm:hidden" type="button" isIconOnly color="success" variant="ghost"
                                 onClick={() => navigate("/pranali/ambassadorials/new-report")}>
                            <Add fill="currentColor" size={28}/>
                        </Button>}
                        <Button type="button"
                                isIconOnly
                                isLoading={ambassadorialReportStatus === 'pending'}
                                isDisabled={ambassadorialReportStatus === 'pending'}
                                color="default" variant="ghost"
                                onClick={() => getAmbassadorial(dispatch, user)}>
                            <Refresh fill="currentColor" size={28}/>
                        </Button>
                    </div>
                </div>
            </div>
            <AmbassadorialTable/>
        </div>
    );
}

