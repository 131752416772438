import Chart from "react-apexcharts";
import React from "react";

export default function Donut_MeetingTypes(props) {
    const height = 330;
    const width = 320;

    const series = [props.meetingTypesCount?.gbm,
        props.meetingTypesCount?.bod,
        props.meetingTypesCount?.jointMeeting,
        props.meetingTypesCount?.pis,
        props.meetingTypesCount?.ocv,
        props.meetingTypesCount?.letterheadExchange,
        props.meetingTypesCount?.anyOther];

    const options = {
        chart: {
            width: width,
            type: 'donut',
        },
        plotOptions: {
            pie: {
                offsetY: 0,
                startAngle: -90,
                endAngle: 270
            },
        },
        dataLabels: {
            enabled: false
        },
        colors: ['#209dfc', '#8b75d7', '#ff0f2e', '#feb629', '#1ae6a1', '#de558e'],
        fill: {
            type: 'gradient',
        },
        labels: ['GBM', 'BOD', "Joint Meetings", "PIS Expectations", 'OCV', "Letterhead Exchange", "Any Other"],
        legend: {
            position: 'bottom',
            // offsetX: 0,
            // offsetY: 10,
            formatter: function (val, opts) {
                return val + " - " + opts.w.globals.series[opts.seriesIndex]
            }
        }
    }

    return (
        <Chart
            options={options}
            series={series}
            type="donut"
            width={width}
            height={height}
        />
    )
}