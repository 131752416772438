import {useDispatch, useSelector} from "react-redux";
import {selectAllUserData} from "../../redux/slice/userSlice";
import React, {useEffect} from "react";
import ViewNominationsClubWise from "./ViewNominationsClubWise";
import {Accordion, AccordionItem} from "@nextui-org/react";
import ViewNominationsProjectsWise from "./ViewNominationsProjectsWise";
import {getProjectsList} from "../../../services/ProjectsServices";

export default function ViewNominationsForDistrictAdmin() {
    const user = useSelector(selectAllUserData);
    const dispatch = useDispatch();

    useEffect(() => {
        getProjectsList(dispatch, user, true);
    }, []);

    return (<>
        <Accordion variant="splitted" defaultExpandedKeys={["1"]}>
            <AccordionItem key="1" aria-label="Club Wise" title="Club Wise">
                <ViewNominationsClubWise/>
            </AccordionItem>
            <AccordionItem key="2" aria-label="Project Wise" title="Project Wise">
                <ViewNominationsProjectsWise/>
            </AccordionItem>
        </Accordion>

    </>);
}