import Chart from "react-apexcharts";
import React from "react";

export default function PolarArea_AmbassadorialTypes(props) {
    const height = 330;
    const width = 320;

    const total = props.ambassadorialTypesCount?.homeDistrictProjects +
        props.ambassadorialTypesCount?.homeDistrictMeetings +
        props.ambassadorialTypesCount?.rotary +
        props.ambassadorialTypesCount?.letterHead +
        props.reportedNewsLettersCount;

    const series = [
        props.ambassadorialTypesCount?.homeDistrictProjects,
        props.ambassadorialTypesCount?.homeDistrictMeetings,
        props.ambassadorialTypesCount?.rotary,
        props.ambassadorialTypesCount?.letterHead,
        props.reportedNewsLettersCount
    ]

    const options = {
        chart: {
            height: height,
            width: width,
            type: 'polarArea',
        },
        labels: ['Home District Projects', 'Home District Meetings', 'Home District Rotary', 'LetterHeads', "News Letters"],
        stroke: {
            colors: ['#fff']
        },
        fill: {
            opacity: 0.8
        },
        yaxis: {
            show: total > 0
        },
        legend: {
            position: 'bottom'
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    }

    return (
        <Chart
            options={options}
            series={series}
            type="polarArea"
            width={width}
            height={height}
        />
    )
}