export const COLUMNS_FINANCE = [
    {name: "TRANSACTION ID", uid: "transactionId", sortable: true},
    {name: "CLUB NAME", uid: "clubName", sortable: true},
    {name: "DATE", uid: "timestamp"},
    {name: "AMOUNT", uid: "amount", sortable: true},
    {name: "STATUS", uid: "paymentStatus", sortable: true},
    {name: "TRANSACTION DONE FOR", uid: "transactionDoneFor"},
    {name: "TRANSACTION TYPE", uid: "transactionType", sortable: true},
];

export const COLUMNS_AARA_CLUB_VIEW = [
    {name: "NOMINATION ID", uid: "nominationId", sortable: true},
    {name: "CLUB NAME", uid: "clubName", sortable: true},
    {name: "NOMINATION TYPE", uid: "nominationType", sortable: true},
    {name: "DATE", uid: "timestamp"},
    {name: "STATUS", uid: "adminData.status", sortable: true}
];

export const COLUMNS_AARA_DISTRICT_VIEW = [
    {name: "CLUB NAME", uid: "clubName", sortable: true},
    {name: "NOMINATION TYPE", uid: "nominationType", sortable: true},
    {name: "PROJECT ID", uid: "projectId", sortable: true},
    {name: "PROJECT NAME", uid: "projectName", sortable: true},
    {name: "NOMINATION FOR", uid: "nominationFor", sortable: true},
];

export const COLUMNS_MEMBERSHIP = [
    {name: "NAME", uid: "memberName", sortable: true},
    {name: "DESIGNATION", uid: "designationsList", sortable: false},
    {name: "AGE", uid: "dateOfBirth", sortable: true},
    {name: "CLUB NAME", uid: "clubName", sortable: true},
    {name: "MEMBERSHIP STATUS", uid: "membershipStatus", sortable: true},
    {name: "PRANALI ID", uid: "pranaliId", sortable: true},
    {name: "RI ID", uid: "rotaryId", sortable: true},
    {name: "PRANALI ACCESS", uid: "pranaliAccess", sortable: true},
    {name: "DCM", uid: "isDCM", sortable: true},
    {name: "ACTIONS", uid: "actions"},
];

export const COLUMNS_AMBASSADORIAL = [
    {name: "AMBASSADORIAL ID", uid: "ambassadorialId", sortable: true},
    {name: "EVENT BY", uid: "eventBy", sortable: true},
    {name: "EVENT NAME", uid: "eventName", sortable: true},
    {name: "AMBASSADORIAL TYPE", uid: "ambassadorialType", sortable: true},
    {name: "REPORT STATUS", uid: "reportStatus", sortable: true},
    {name: "CLUB NAME", uid: "clubName", sortable: true},
    {name: "ACTIONS", uid: "actions"},
];

export const COLUMNS_MEETINGS = [
    {name: "MEETING ID", uid: "meetingId", sortable: true},
    {name: "DATE", uid: "endTime", sortable: true},
    {name: "MEETING TYPE", uid: "meetingType", sortable: true},
    {name: "REPORT MONTH", uid: "reportingMonth", sortable: true},
    {name: "REPORT STATUS", uid: "reportStatus", sortable: true},
    {name: "CLUB NAME", uid: "clubName", sortable: true},
    {name: "ACTIONS", uid: "actions"},
];

export const COLUMNS_PROJECTS = [
    {name: "PROJECT ID", uid: "projectId", sortable: true},
    {name: "NAME", uid: "name", sortable: true},
    {name: "REPORT STATUS", uid: "reportStatus", sortable: true},
    {name: "REPORT MONTH", uid: "reportingMonth", sortable: true},
    {name: "AVENUE MAIN", uid: "avenueMain", sortable: true},
    {name: "AVENUE OPTIONAL", uid: "avenueOptional", sortable: true},
    {name: "CLUB NAME", uid: "clubName", sortable: true},
    {name: "ACTIONS", uid: "actions"},
];

export const COLUMNS_CLUBS_IN_DISTRICT = [
    {name: "CLUB CODE", uid: "clubCode", sortable: true},
    {name: "CLUB NAME", uid: "name", sortable: true},
    {name: "ZONE", uid: "zone", sortable: true},
    {name: "CLUB TYPE", uid: "type", sortable: true},
    {name: "CHARTER DATE", uid: "charterDate", sortable: true},
    {name: "ACTIONS", uid: "actions"},
];
