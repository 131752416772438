import React from "react";

import {useSelector} from "react-redux";
import {Chip, Divider, User} from "@nextui-org/react";
import {selectSelectedMemberData} from "../../redux/slice/membershipSlice";
import {PRANALI_ACCESS_VIEW_FOR_MEMBERSHIP} from "../../../constants/WebPageConstants";
import {getImageFromDrive} from "../../common/DetailsViewHelpers";

export default function ViewMemberData() {
    const selectedMemberData = useSelector(selectSelectedMemberData);

    return (<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <div className="flex flex-col col-span-full">
            <div className="justify-start">
                <User
                    name={<h1 className="text-large font-medium">{selectedMemberData.basicData.name}</h1>}
                    description={<>
                        <p className="text-sm">{selectedMemberData.basicData.designation.map(value =>
                            <Chip key={value} className="m-0.5" size="sm" color="secondary" variant="dot">
                                {value}
                            </Chip>
                        )} {selectedMemberData?.clubName ? "@" + selectedMemberData.clubName : ''}</p>
                        {selectedMemberData.councilData &&
                            <p className="mt-2 text-sm items-center">
                                        <span>
                                            <Chip className="mr-1.5" size="sm" color="warning" variant="dot">
                                                {selectedMemberData.councilData.designation}
                                            </Chip>
                                        </span>
                                @Rotaract District 3142
                            </p>
                        }
                    </>}
                    avatarProps={{
                        showFallback: false,
                        size: "lg",
                        src: getImageFromDrive(selectedMemberData.basicData.profileImageUrl)
                    }}
                />
                <Divider className="my-4 mb-4"/>
            </div>
        </div>

        <div className="flex flex-col">
            <p className="text-md">Membership Status</p>
            <p className="text-small text-default-500">{selectedMemberData.membershipStatus}</p>
        </div>
        {selectedMemberData?.pranaliId && <div className="flex flex-col">
            <p className="text-md">Pranali Id</p>
            <p className="text-small text-default-500">{selectedMemberData.pranaliId}</p>
        </div>}
        {selectedMemberData?.rotaryId && <div className="flex flex-col">
            <p className="text-md">Rotary International Id</p>
            <p className="text-small text-default-500">{selectedMemberData.rotaryId}</p>
        </div>}
        <div className="flex flex-col">
            <p className="text-md">Pranali Access</p>
            <p className="text-small text-default-500">
                {PRANALI_ACCESS_VIEW_FOR_MEMBERSHIP.includes(selectedMemberData.pranaliAccess)
                    ? selectedMemberData.pranaliAccess : selectedMemberData.pranaliAccess === "Bronze"
                        ? "Pres-Sec" : "Council"}
            </p>
        </div>
        {selectedMemberData.duesPaidStatus && <div className="flex flex-col">
            <p className="text-md">Dues Paid On</p>
            <p className="text-small text-default-500">{new Date(selectedMemberData.duesPaidOnDate.replace("T", " ")).toLocaleString()}</p>
        </div>}

        <Divider className="my-4 mb-4 col-span-full"/>
        <div className="flex flex-col">
            <p className="text-md">Email Id</p>
            <p className="text-small text-default-500">{selectedMemberData.basicData.email}</p>
        </div>
        <div className="flex flex-col">
            <p className="text-md">Primary Contact Number</p>
            <p className="text-small text-default-500">{selectedMemberData.basicData.contact}</p>
        </div>
        {selectedMemberData.basicData?.contactSecondary && <div className="flex flex-col">
            <p className="text-md">Secondary Contact Number</p>
            <p className="text-small text-default-500">{selectedMemberData.basicData.contactSecondary}</p>
        </div>}
        <div className="flex flex-col">
            <p className="text-md">Date Of Birth</p>
            <p className="text-small text-default-500">{new Date(selectedMemberData.basicData.dateOfBirth).toDateString()}</p>
        </div>
        <div className="flex flex-col">
            <p className="text-md">Blood Group</p>
            <p className="text-small text-default-500">{selectedMemberData.basicData.bloodGroup}</p>
        </div>
        <div className="flex flex-col">
            <p className="text-md">Gender</p>
            <p className="text-small text-default-500">{selectedMemberData.basicData.gender}</p>
        </div>
        <div className="flex flex-col col-span-full">
            <p className="text-md">Address</p>
            <p className="text-small text-default-500">{selectedMemberData.basicData.address}</p>
        </div>
    </div>);
}