import {createSlice} from '@reduxjs/toolkit';

const initialMembershipState = {
    membershipData: [],
    membershipDataStatus: 'idle',

    searchedMembersData: [],
    searchedMembersDataStatus: 'idle',

    selectedMemberData: null,
    selectedMemberDataStatus: 'idle',

    editedMemberDataStatus: 'idle',

    dCMembersData: [],
    dCMembersDataStatus: 'idle',
}

const membershipSlice = createSlice({
    name: "membership",
    initialState: initialMembershipState,
    reducers: {
        setMembershipData: (state, action) => {
            state.membershipData = action.payload.reverse();
        },
        setMembershipDataStatus: (state, action) => {
            state.membershipDataStatus = action.payload;
        },

        setSearchedMembersData: (state, action) => {
            state.searchedMembersData = action.payload;
        },
        setSearchedMembersDataStatus: (state, action) => {
            state.searchedMembersDataStatus = action.payload;
        },

        setSelectedMemberData: (state, action) => {
            state.selectedMemberData = action.payload;
        },
        setSelectedMemberDataStatus: (state, action) => {
            state.selectedMemberDataStatus = action.payload;
        },

        setEditedMembershipDataStatus: (state, action) => {
            state.editedMemberDataStatus = action.payload;
        },

        setDistrictCouncilMembersData: (state, action) => {
            state.dCMembersData = action.payload.reverse();
        },
        setDistrictCouncilMembersDataStatus: (state, action) => {
            state.dCMembersDataStatus = action.payload;
        },

        setAllMembershipNull: (state) => {
            state.membershipData = [];
            state.membershipDataStatus = 'idle';

            state.searchedMembersData = [];
            state.searchedMembersDataStatus = 'idle';

            state.selectedMemberData = null;
            state.selectedMemberDataStatus = 'idle';

            state.editedMemberDataStatus = 'idle';

            state.dCMembersData = [];
            state.dCMembersDataStatus = 'idle';
        }
    }
});

export const {
    setMembershipData,
    setMembershipDataStatus,

    setSearchedMembersData,
    setSearchedMembersDataStatus,

    setSelectedMemberData,
    setSelectedMemberDataStatus,

    setEditedMembershipDataStatus,

    setDistrictCouncilMembersData,
    setDistrictCouncilMembersDataStatus,

    setAllMembershipNull,
} = membershipSlice.actions;

export const selectMembershipData = (state) => state.membership.membershipData;
export const getMembershipDataStatus = (state) => state.membership.membershipDataStatus;

export const selectSearchedMembersData = (state) => state.membership.searchedMembersData;
export const getSearchedMembersDataStatus = (state) => state.membership.searchedMembersDataStatus;

export const selectSelectedMemberData = (state) => state.membership.selectedMemberData;
export const getSelectedMemberDataStatus = (state) => state.membership.selectedMemberDataStatus;

export const getEditedMembershipDataStatus = (state) => state.membership.editedMemberDataStatus;

export const selectDistrictCouncilMembersData = (state) => state.membership.dCMembersData;
export const getDistrictCouncilMembersDataStatus = (state) => state.membership.dCMembersDataStatus;
export default membershipSlice.reducer;