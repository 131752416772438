class ApiEndpoint {

    prodEndPointURL = "https://805ngyxbe7.execute-api.ap-south-1.amazonaws.com/prod";

    stageEndPointURL = "https://99a6fpkmxi.execute-api.ap-south-1.amazonaws.com/dev";

    localEndPointURL = "http://localhost:8080"

    setEnvironmentURL() {
        return this.stageEndPointURL;
    }

    getHeaders(user) {
        return {
            "Authorization": "Bearer " + user.credentials,
        };
    }

    getHeadersWithContentType(user) {
        return {
            "Authorization": "Bearer " + user.credentials,
            "Content-Type" : "application/json"
        };
    }

    getMemberID(user) {
        let memberId = user.membershipId;
        return '?memberId=' + memberId;
    }


    getClubCode(clubCode) {
        if (null !== clubCode)
            return "&clubCode=" + clubCode;
        else
            return '';
    }

    getMembershipStatus(membershipStatus) {
        if (null !== membershipStatus)
            return "&membershipStatus=" + membershipStatus;
        else
            return '';
    }

    getExistingMembershipId(existingMembershipId) {
        if (null !== existingMembershipId && undefined !== existingMembershipId)
            return "&existingMembershipId=" + existingMembershipId;
        else
            return '';
    }

    getMemberInfo(user) {
        return {
            uuid: user.membershipId,
            name: user.name
        }
    }

    // LOGIN -----------------------------------------------------------------------------------------------------------
    getJwtResponse(credential) {
        return {
            method: 'get',
            url: this.setEnvironmentURL() + "/auth",
            headers: {
                "Authorization": "Bearer " + credential
            }
        }
    }


    // CLUB DATA ENDPOINT ----------------------------------------------------------------------------------------------
    getClubDataById(user, clubCode) {
        return {
            method: 'get',
            url: this.setEnvironmentURL() + "/clubs" + this.getMemberID(user) + this.getClubCode(clubCode),
            headers: this.getHeaders(user)
        }
    }

    getClubsList(user) {
        return {
            method: 'get',
            url: this.setEnvironmentURL() + "/clubs/list" + this.getMemberID(user),
            headers: this.getHeaders(user)
        }
    }

    onBoardNewClub(user, onBoardNewClub) {
        return {
            method: 'patch',
            url: this.setEnvironmentURL() + "/clubs/onboard-new" + this.getMemberID(user),
            headers: this.getHeaders(user),
            data: onBoardNewClub
        }
    }

    updateHomeClubData(user, updateHomeClubData) {
        return {
            method: 'post',
            url: this.setEnvironmentURL() + "/clubs/update-home-club-data" + this.getMemberID(user),
            headers: this.getHeaders(user),
            data: updateHomeClubData
        }
    }

    updateClubDataByClubCode(user, adminUpdateForExistingClub) {
        return {
            method: 'patch',
            url: this.setEnvironmentURL() + "/clubs/update-club-" + user.clubCode + this.getMemberID(user),
            headers: this.getHeaders(user),
            data: adminUpdateForExistingClub
        }
    }


    //  MEMBERSHIP ENDPOINT --------------------------------------------------------------------------------------------
    getMemberDataById(user, dataForId) {
        return {
            method: 'get',
            url: this.setEnvironmentURL() + "/members/" + dataForId + this.getMemberID(user),
            headers: this.getHeaders(user),
        }
    }

    getMembersList(user, isDcmList, membershipStatus) {
        return {
            method: "get",
            url: this.setEnvironmentURL() + '/members' + this.getMemberID(user) + '&isDcmList=' + isDcmList + this.getMembershipStatus(membershipStatus),
            headers: this.getHeaders(user)
        }
    }

    searchMemberDataByBy(user, memberName, dateOfBirth) {
        return {
            method: "get",
            url: this.setEnvironmentURL() + '/members/search' + this.getMemberID(user) + '&memberName=' + memberName + '&dateOfBirth=' + dateOfBirth,
            headers: this.getHeaders(user)
        }
    }

    addMember(user, existingMembershipId, createMember) {
        return {
            method: "post",
            url: this.setEnvironmentURL() + '/members/add-new' + this.getMemberID(user) + this.getExistingMembershipId(existingMembershipId),
            headers: this.getHeaders(user),
            data: createMember
        }
    }

    editMember(user, membershipId, updateRequest) {
        return {
            method: "patch",
            url: this.setEnvironmentURL() + '/members/edit-' + membershipId + this.getMemberID(user),
            headers: this.getHeaders(user),
            data: updateRequest
        }
    }


    // PROJECTS ENDPOINT -----------------------------------------------------------------------------------------------
    getProjectsList(user, isForNominations) {
        return {
            method: "get",
            url: this.setEnvironmentURL() + '/projects' + this.getMemberID(user) + '&isForNominations=' + isForNominations,
            headers: this.getHeaders(user)
        }
    }

    getProjectsListForAmbassadorial(user, clubCode) {
        return {
            method: "get",
            url: this.setEnvironmentURL() + '/projects/ambassadorial' + this.getMemberID(user) + '&clubCode=' + clubCode,
            headers: this.getHeaders(user)
        }
    }

    getProjectById(user, projectId, purpose) {
        return {
            method: "get",
            url: this.setEnvironmentURL() + '/projects/' + projectId + this.getMemberID(user) + '&purpose=' + purpose,
            headers: this.getHeaders(user)
        }
    }

    addProjectReport(user, projectReport) {
        return {
            method: "post",
            url: this.setEnvironmentURL() + '/projects/report-new' + this.getMemberID(user),
            headers: this.getHeaders(user),
            data: projectReport,
        };
    }

    updateProjectReport(user, projectId, projectReport) {
        return {
            method: "patch",
            url: this.setEnvironmentURL() + '/projects/update-' + projectId + this.getMemberID(user),
            headers: this.getHeaders(user),
            data: projectReport,
        };
    }

    addComment(user, projectId, feedbackMessage) {
        let feedback = {
            name: user.name,
            message: feedbackMessage,
        }

        return {
            method: "patch",
            url: this.setEnvironmentURL() + '/projects/add-comment-' + projectId + this.getMemberID(user),
            headers: this.getHeaders(user),
            data: feedback,
        };
    }

    addLike(user, projectId) {
        return {
            method: "patch",
            url: this.setEnvironmentURL() + '/projects/add-like-' + projectId + this.getMemberID(user),
            headers: this.getHeaders(user),
            data: this.getMemberInfo(user),
        };
    }

    deleteProjectReport(user, projectId) {
        return {
            method: "delete",
            url: this.setEnvironmentURL() + '/projects/delete-' + projectId + this.getMemberID(user),
            headers: this.getHeaders(user)
        };
    }


    // MEETINGS ENDPOINT
    getMeetingsList(user) {
        return {
            method: 'get',
            url: this.setEnvironmentURL() + '/meetings' + this.getMemberID(user),
            headers: this.getHeaders(user)
        };
    }

    getMoMListForAmbassadorial(user, clubCode) {
        return {
            method: "get",
            url: this.setEnvironmentURL() + '/meetings/ambassadorial' + this.getMemberID(user) + '&clubCode=' + clubCode,
            headers: this.getHeaders(user)
        }
    }

    getMoMById(user, meetingId, purpose) {
        return {
            method: "get",
            url: this.setEnvironmentURL() + '/meetings/' + meetingId + this.getMemberID(user) + '&purpose=' + purpose,
            headers: this.getHeaders(user)
        }
    }

    addMinutesOfMeeting(user, meetingData) {
        return {
            method: 'post',
            url: this.setEnvironmentURL() + "/meetings/report-new" + this.getMemberID(user),
            headers: this.getHeaders(user),
            data: meetingData,
        };
    }

    updateMeetingForClub(user, meetingId, reportData) {
        return {
            method: "patch",
            url: this.setEnvironmentURL() + '/meetings/update-' + meetingId + this.getMemberID(user),
            headers: this.getHeaders(user),
            data: reportData
        }
    }

    deleteMoMReport(user, meetingId) {
        return {
            method: "delete",
            url: this.setEnvironmentURL() + '/meetings/delete-' + meetingId + this.getMemberID(user),
            headers: this.getHeaders(user),
        }
    }


    // AMBASSADORIAL DATA
    getAmbassadorialList(user) {
        return {
            method: "get",
            url: this.setEnvironmentURL() + '/ambassadorial' + this.getMemberID(user),
            headers: this.getHeaders(user)
        }
    }

    getAmbassadorialById(user, ambassadorialId, purpose) {
        return {
            method: "get",
            url: this.setEnvironmentURL() + '/ambassadorial/' + ambassadorialId + this.getMemberID(user) + '&purpose=' + purpose,
            headers: this.getHeaders(user)
        }
    }

    addAmbassadorial(user, reportData) {
        return {
            method: "post",
            url: this.setEnvironmentURL() + '/ambassadorial/report-new' + this.getMemberID(user),
            headers: this.getHeaders(user),
            data: reportData
        }
    }

    updateAmbassadorialReport(user, ambassadorialId, reportData) {
        return {
            method: "patch",
            url: this.setEnvironmentURL() + '/ambassadorial/update-' + ambassadorialId + this.getMemberID(user),
            headers: this.getHeaders(user),
            data: reportData
        }
    }

    deleteAmbassadorialReport(user, ambassadorialId,) {
        return {
            method: "delete",
            url: this.setEnvironmentURL() + '/ambassadorial/delete-' + ambassadorialId + this.getMemberID(user),
            headers: this.getHeaders(user),
        }
    }

    // FINANCES
    getFinanceRecords(user) {
        return {
            method: "get",
            url: this.setEnvironmentURL() + '/finances' + this.getMemberID(user),
            headers: this.getHeaders(user),
        }
    }

    getFinancialRecordsByTransactionId(user, transactionId) {
        return {
            method: "get",
            url: this.setEnvironmentURL() + '/finances/' + transactionId + this.getMemberID(user),
            headers: this.getHeaders(user),
        }
    }

    createOrder(user, amount) {
        return {
            method: "post",
            url: this.setEnvironmentURL() + '/finances/create-order' + this.getMemberID(user) + '&amount=' + amount,
            headers: this.getHeaders(user),
        }
    }

    capturePayment(user, requestBody) {
        return {
            method: "post",
            url: this.setEnvironmentURL() + '/finances/capture-payment' + this.getMemberID(user),
            headers: this.getHeaders(user),
            data: requestBody
        }
    }

    payAdminDues(user) {
        return {
            method: "patch",
            url: this.setEnvironmentURL() + '/finances/pay-admin-dues' + this.getMemberID(user),
            headers: this.getHeaders(user),
        }
    }

    updateOrderStatus(user, orderId, financeUpdateRequest) {
        return {
            method: "patch",
            url: this.setEnvironmentURL() + '/finances/update-order-status/' + orderId + this.getMemberID(user),
            headers: this.getHeaders(user),
            data: financeUpdateRequest
        }
    }

//  AARA's
    getAaraNominations(user) {
        return {
            method: "get",
            url: this.setEnvironmentURL() + '/aaras?memberId=' + user.membershipId,
            headers: this.getHeaders(user)
        }
    }

    addAaraNominations(user, body) {
        return {
            method: "post",
            url: this.setEnvironmentURL() + '/aaras/new-nomination?memberId=' + user.membershipId,
            headers: this.getHeaders(user),
            data: body
        }
    }

    updateAaraNominations(user, body) {
        return {
            method: "patch",
            url: this.setEnvironmentURL() + '/aaras/update-nomination?memberId=' + user.membershipId,
            headers: this.getHeaders(user),
            data: body
        }
    }

    initiateIndividualAaraNominations(user, body) {
        return {
            method: "post",
            url: this.setEnvironmentURL() + '/aaras/individual-new-nomination?memberId=' + user.membershipId,
            headers: this.getHeaders(user),
            data: body
        }
    }

    addOrUpdateIndividualAaraNominations(user, body) {
        return {
            method: "patch",
            url: this.setEnvironmentURL() + '/aaras/add-individual-nomination-category?memberId=' + user.membershipId,
            headers: this.getHeaders(user),
            data: body
        }
    }

    submitCouncilApplication(body) {
        return {
            method: "post",
            url: this.setEnvironmentURL() + '/council57/submit',
            data: body
        }
    }

    getCouncilApplications(user) {
        return {
            method: "get",
            url: this.setEnvironmentURL() + '/council57?memberId=' + user.membershipId,
            headers: this.getHeaders(user)
        }
    }

//  R.I.District Data
    getHomeDistrictData(user) {
        return {
            method: "get",
            url: this.setEnvironmentURL() + '/rid' + this.getMemberID(user),
            headers: this.getHeaders(user)
        }
    }

    updateHomeDistrictData(user, updateRiDistrictRequest) {
        return {
            method: "patch",
            url: this.setEnvironmentURL() + '/rid/update-' + user.districtId + this.getMemberID(user),
            headers: this.getHeaders(user),
            body: updateRiDistrictRequest
        }
    }

    updateHomeDistrictAwardsData(user, awardsDetailsList) {
        return {
            method: "patch",
            url: this.setEnvironmentURL() + '/rid/update-' + user.districtId + '-awards' + this.getMemberID(user),
            headers: this.getHeaders(user),
            body: awardsDetailsList
        }
    }

    updateHomeDistrictDeadlinesData(user, reportDeadlines) {
        return {
            method: "patch",
            url: this.setEnvironmentURL() + '/rid/update-' + user.districtId + '-deadlines' + this.getMemberID(user),
            headers: this.getHeaders(user),
            body: JSON.parse(JSON.stringify(reportDeadlines))
        }
    }
}

export default ApiEndpoint;