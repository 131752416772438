import {useDispatch, useSelector} from "react-redux";
import {selectAllUserData} from "../../redux/slice/userSlice";
import RadialBar_ReportsStatus from "./charts/RadialBar_ReportsStatus";
import {Card, CardBody, Skeleton} from "@nextui-org/react";
import Donut_MeetingTypes from "./charts/Donut_MeetingTypes";
import {
    selectHomeClubData,
    getHomeClubDataFetchStatus, setClubsList, setClubsListFetchStatus,
    setHomeClubData,
    setHomeClubDataFetchStatus
} from "../../redux/slice/clubDataSlice";
import ApiEndpoint from "../../../services/ApiEndpoint";
import axios from "axios";
import {throwErrorToast} from "../aaras/NewNominationForProjects";
import React, {useEffect} from "react";
import Line_ClubWallet from "./charts/Line_ClubWallet";
import './charts/charts.scss';
import PolarArea_AmbassadorialTypes from "./charts/PolarArea_AmbassadorialTypes";

function Dashboard() {
    const dispatch = useDispatch();
    const user = useSelector(selectAllUserData);
    const homeClubData = useSelector(selectHomeClubData);
    const fetchHomeClubDataStatus = useSelector(getHomeClubDataFetchStatus);

    useEffect(() => {
        getClubDataById(dispatch, user);
        getClubsList(dispatch, user);
    }, []);

    return (<>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 mb-5">
            <div>
                <Card className="box2">
                    <CardBody className="flex flex-row text-white">
                        <div className="grid grid-cols-1 gap-0 content-end basis-2/4">
                            <Skeleton isLoaded={'complete' === fetchHomeClubDataStatus} className="rounded-lg">
                                <div className="font-bold text-large">{homeClubData?.wallet?.credit}</div>
                            </Skeleton>
                            <h4 className="font-bold text-large">Wallet Total Credit</h4>
                        </div>
                        <div className="basis-2/4"><Line_ClubWallet data={[12, 14, 2, 47, 32, 44, 14, 55, 41, 69]}/>
                        </div>
                    </CardBody>
                </Card>
            </div>
            <div>
                <Card className="box3">
                    <CardBody className="flex flex-row text-white">
                        <div className="fgrid grid-cols-1 gap-0 content-end basis-2/4">
                            <Skeleton isLoaded={'complete' === fetchHomeClubDataStatus} className="rounded-lg">
                                <h4 className="font-bold text-large">{homeClubData?.wallet?.debit}</h4>
                            </Skeleton>
                            <h4 className="font-bold text-large">Wallet Total Debit</h4>
                        </div>
                        <Line_ClubWallet data={[25, 66, 41, 59, 25, 44, 12, 36, 9, 21]}/>
                    </CardBody>
                </Card>
            </div>
            <div>
                <Card className="box4">
                    <CardBody className="flex flex-row text-white">
                        <div className="grid grid-cols-1 gap-0 content-end basis-2/4">
                            <Skeleton isLoaded={'complete' === fetchHomeClubDataStatus} className="rounded-lg">
                                <h4 className="font-bold text-large">{homeClubData?.wallet?.balance}</h4>
                            </Skeleton>
                            <h4 className="font-bold text-large">Current Balance</h4>
                        </div>
                        <Line_ClubWallet data={[15, 75, 47, 65, 14, 32, 19, 54, 44, 61]}/>
                    </CardBody>
                </Card>
            </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            <div>
                <Card>
                    <CardBody>
                        <h4 className="font-bold text-large">Project Status</h4>
                        <Skeleton isLoaded={'complete' === fetchHomeClubDataStatus} className="rounded-lg">
                            <RadialBar_ReportsStatus reportStatus={homeClubData?.activities?.projectsStatus}
                                                     reportedCount={homeClubData?.activities?.reportedProjectsCount}/>
                        </Skeleton>
                    </CardBody>
                </Card>
            </div>
            <div>
                <Card>
                    <CardBody>
                        <h4 className="font-bold text-large">Meetings Status</h4>
                        <RadialBar_ReportsStatus reportStatus={homeClubData?.activities?.meetingsStatus}
                                                 reportedCount={homeClubData?.activities?.reportedMeetingsCount}/>
                    </CardBody>
                </Card>
            </div>
            <div>
                <Card>
                    <CardBody>
                        <h4 className="font-bold text-large">Meeting Types</h4>
                        <Donut_MeetingTypes meetingTypesCount={homeClubData?.activities?.meetingTypesCount}/>
                    </CardBody>
                </Card>
            </div>

            <div>
                <Card>
                    <CardBody>
                        <h4 className="font-bold text-large">Ambassadorial Types</h4>
                        <PolarArea_AmbassadorialTypes
                            ambassadorialTypesCount={homeClubData?.activities?.ambassadorialTypesCount}
                            reportedNewsLettersCount={homeClubData?.activities?.reportedNewsLettersCount}/>
                    </CardBody>
                </Card>
            </div>
        </div>
    </>);
}

export default Dashboard;

export function getClubDataById(dispatch, user) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setHomeClubDataFetchStatus("pending"));

    return axios(apiEndPoint.getClubDataById(user, null))
        .then(res => {
            if (res.status === 200) {
                dispatch(setHomeClubData(res.data));
                dispatch(setHomeClubDataFetchStatus("complete"));
            }
            return res;
        })
        .catch(e => {
            dispatch(setHomeClubDataFetchStatus("complete"));
            throwErrorToast(e);
        });
}

export function getClubsList(dispatch, user) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setClubsListFetchStatus("pending"));

    return axios(apiEndPoint.getClubsList(user))
        .then(res => {
            if (res.status === 200) {
                dispatch(setClubsList(res.data));
                dispatch(setClubsListFetchStatus("complete"));
            }
            return res;
        })
        .catch(e => {
            dispatch(setClubsListFetchStatus("complete"));
            throwErrorToast(e);
        });
}