import {useSelector, useDispatch} from "react-redux";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {
    selectAllNominations,
    selectFetchStatus,
    setDataForEdit,
    setIndexForEdit, setProjectNominationsCount, setProjectNominationsList
} from "../../redux/slice/aaraSlice";
import React, {useEffect} from "react";
import {ToastContainer} from "react-toastify";
import {
    NOMINATION_STATUS_DRAFT, NOMINATION_STATUS_SUBMITTED,
    QUARTERLY_NOMINATION_CATEGORIES,
} from "../../../constants/WebPageConstants";
import {getProjectsList} from "../../../services/ProjectsServices";
import {getAaraNominations, initiateIndividualNominations} from "../../../services/NominationServices";
import {useNavigate} from "react-router-dom";
import {
    Accordion,
    AccordionItem,
    Button, Card,
    CardBody,
    CardHeader,
    Chip,
    Divider, getKeyValue,
    Progress,
    Table, TableBody, TableCell, TableColumn, TableHeader, TableRow
} from "@nextui-org/react";
import {Add, Check, Error, Info, Refresh} from "../../Icons/SystemIcons";
import {EditIcon} from "../../Icons/Icons";

export default function ViewNominations() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector(selectAllUserData);
    const aara = useSelector(selectAllNominations);
    const fetchStatus = useSelector(selectFetchStatus);

    const quarterlyNominationsData = aara.filter(nomination =>
        QUARTERLY_NOMINATION_CATEGORIES.includes(nomination.nominationType));

    const columnsForProjectBasedNominations = [
        {
            key: "projectId",
            label: "Project Id"
        },
        {
            key: "projectName",
            label: "Project Name"
        },
        {
            key: "nominationFor",
            label: "Nomination For"
        }
    ];

    const columnsForIndividualNomination = [
        {
            key: "pranaliId",
            label: "Pranali Id"
        },
        {
            key: "memberName",
            label: "Member Name"
        },
        {
            key: "nominationFor",
            label: "Nomination For"
        },
        {
            key: "isPartialSubmit",
            label: "Status"
        }
    ];

    const statusColorMap = {
        false: "success",
        true: "warning",
    };

    const renderCell = React.useCallback((user, columnKey) => {
        const cellValue = user[columnKey];

        switch (columnKey) {
            case "isPartialSubmit":
                return (
                    <Chip className="capitalize" color={statusColorMap[user.isPartialSubmit]} size="sm" variant="flat">
                        {cellValue ? "Draft" : "Submitted"}
                    </Chip>
                );
            default:
                return cellValue;
        }
    }, []);

    async function onIndividualNominationInitiation() {
        let data = {
            "nominationType": "Individual",
            "isDraftRequest": true
        }
        await initiateIndividualNominations(dispatch, user, data).then(res => {
                if (res.status === 201)
                    navigate("/pranali/aara/new-nomination-individual");
            }
        );
    }

    async function addPropertyToIndiNomination(index, nomination) {
        dispatch(setIndexForEdit(index));
        dispatch(setDataForEdit(nomination));
        navigate("/pranali/aara/new-nomination-individual");
    }

    function setProjectNominationsListAndStoreData(index, nomination) {
        dispatch(setIndexForEdit(index));
        dispatch(setDataForEdit(nomination));

        let tempList = JSON.parse(JSON.stringify(nomination.projectNominations));
        let count = 0;
        tempList.forEach(nomination => {
            nomination["id"] = count;
            count++;
        })

        dispatch(setProjectNominationsList(tempList));
        dispatch(setProjectNominationsCount(count));
        navigate("/pranali/aara/new-nomination-projects");
    }

    useEffect(() => {
        getProjectsList(dispatch, user, true);
        getAaraNominations(dispatch, user);
    }, []);

    return (<>
            <ToastContainer/>
            <Card>
                <CardHeader className="flex gap-3 justify-between">
                    <h2 className="text-large font-semibold leading-none text-default-600">All Nominations</h2>
                    <div className="flex gap-3">
                        <Button type="button" isIconOnly color="default" variant="ghost">
                            <Refresh fill="currentColor" size={28}/>
                        </Button>
                        <Button color="secondary" variant="ghost"
                                startContent={<Add fill="currentColor" size={24}/>}
                                onClick={() => navigate("/pranali/aara/new-nomination-projects")}>
                            New Project Nomination
                        </Button>
                        <Button color="secondary" variant="flat"
                                onClick={() => onIndividualNominationInitiation()}>
                            Initiate Individual Nomination
                        </Button>
                    </div>
                </CardHeader>
                <Divider/>
                <CardBody>
                    {(null != quarterlyNominationsData && quarterlyNominationsData.length === 0 && fetchStatus === "complete") &&
                        <div className="mt-10 mb-10 w-full flex flex-col  text-center content-center">
                            <p className="text-lg font-regular text-gray-500">No AARA nominations found for your
                                club</p>
                            <p className="text-md font-regular font-semibold text-gray-600">Let's start by nominating
                                projects</p>
                        </div>}
                    {(null != quarterlyNominationsData && quarterlyNominationsData.length === 0 && fetchStatus === "pending") &&
                        <div className="mt-10 mb-10 w-full flex flex-col  text-center content-center">
                            <Progress size="sm" isIndeterminate aria-label="Loading..."/>
                            <p className="text-sm font-regular leading-7 text-gray-900">
                                Please wait while we search your previously submitted nominations
                            </p>
                        </div>}
                    <Accordion variant="splitted">
                        {aara.map((nomination, index) => (
                            QUARTERLY_NOMINATION_CATEGORIES.includes(nomination.nominationType) && nomination.clubName === user.clubName
                                ? <AccordionItem
                                    key={index} title={nomination.nominationType}
                                    aria-label={nomination.clubName + " " + nomination.nominationType}
                                    subtitle={nomination.adminData.status}
                                    classNames={{
                                        subtitle: `${nomination.adminData.status === NOMINATION_STATUS_DRAFT
                                            ? 'text-warning'
                                            : nomination.adminData.status === NOMINATION_STATUS_SUBMITTED
                                                ? 'text-success'
                                                : 'text-danger'}`
                                    }}
                                    startContent={nomination.adminData.status === NOMINATION_STATUS_DRAFT
                                        ? <Info className="text-warning" fill="currentColor" size={28}/>
                                        : nomination.adminData.status === NOMINATION_STATUS_SUBMITTED
                                            ? <Check className="text-success" fill="currentColor" size={28}/>
                                            : <Error className="text-danger" fill="currentColor" size={28}/>}>
                                    <Table removeWrapper aria-label={nomination.nominationType}
                                           className={`${nomination.adminData.status === NOMINATION_STATUS_DRAFT ? null : "mb-4"}`}>
                                        <TableHeader columns={columnsForProjectBasedNominations}>
                                            {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
                                        </TableHeader>
                                        <TableBody items={nomination.projectNominations}>
                                            {(item) => (
                                                <TableRow key={item.projectId}>
                                                    {(columnKey) => <TableCell>{getKeyValue(item, columnKey)}</TableCell>}
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                    {(nomination.clubName === user.clubName && nomination.adminData.status === NOMINATION_STATUS_DRAFT) &&
                                        <div className="mt-5 mb-4">
                                            <Button color="secondary" variant="ghost"
                                                    href="/pranali/aara/new-nomination-projects"
                                                    startContent={<EditIcon fill="currentColor" size={24}/>}
                                                    onClick={() => setProjectNominationsListAndStoreData(index, nomination)}>
                                                Edit
                                            </Button>
                                        </div>}
                                </AccordionItem>
                                : nomination.nominationType === "Individual" ? <AccordionItem
                                    key={index} title={nomination.nominationType}
                                    aria-label={nomination.clubName + " " + nomination.nominationType}
                                    subtitle={nomination.adminData.status}
                                    classNames={{
                                        subtitle: `${nomination.adminData.status === NOMINATION_STATUS_DRAFT
                                            ? 'text-warning'
                                            : nomination.adminData.status === NOMINATION_STATUS_SUBMITTED
                                                ? 'text-success'
                                                : 'text-danger'}`
                                    }}
                                    startContent={nomination.adminData.status === NOMINATION_STATUS_DRAFT
                                        ? <Info className="text-warning" fill="currentColor" size={28}/>
                                        : nomination.adminData.status === NOMINATION_STATUS_SUBMITTED
                                            ? <Check className="text-success" fill="currentColor" size={28}/>
                                            : <Error className="text-danger" fill="currentColor" size={28}/>}>
                                    <Table removeWrapper aria-label="Individual Nomination"
                                           className="mb-4">
                                        <TableHeader columns={columnsForIndividualNomination}>
                                            {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
                                        </TableHeader>
                                        <TableBody items={nomination.individualNominationList}>
                                            {(item) => (
                                                <TableRow key={item}>
                                                    {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                    {(nomination.clubName === user.clubName && nomination.adminData.status === NOMINATION_STATUS_DRAFT) &&
                                        <div className="mt-5 mb-4">
                                            <Button color="secondary" variant="ghost"
                                                    href="/pranali/aara/new-nomination-projects"
                                                    onClick={() => addPropertyToIndiNomination(index, nomination)}>
                                                Edit
                                            </Button>
                                        </div>}
                                </AccordionItem> : null
                        ))}
                    </Accordion>
                </CardBody>
            </Card>
        </>
    );
}



