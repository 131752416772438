import React, {useEffect} from "react";

import ApiEndpoint from "../../services/ApiEndpoint";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {toastOptions} from "../../constants/WebPageConstants";
import {useDispatch, useSelector} from "react-redux";
import {selectAllUserData} from "../redux/slice/userSlice";
import {set57Status, setApplications} from "../redux/slice/application57Slice";
import TableResponseView from "./TableResponseView";

export default function ApplicationResponses() {
    const user = useSelector(selectAllUserData);
    const dispatch = useDispatch();

    useEffect(() => {
        getCouncilApplications(dispatch, user);
    }, []);

    return (<>
            <ToastContainer/>
            <TableResponseView/>
        </>
    );

}

export function getCouncilApplications(dispatch, user) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(set57Status("pending"));

    return axios(apiEndPoint.getCouncilApplications(user))
        .then(res => {
            if (res.status === 200) {
                dispatch(setApplications(res.data));
                dispatch(set57Status("complete"));
            }
            return res
        })
        .catch((e) => {
            dispatch(set57Status("complete"));
            toast.error(<>
                <p className="text-medium">{null != e?.response?.data?.code ? e.response.data.code.replaceAll("_", "") : "Internal Server Error"}</p>
                <p className="text-small">{null != e?.response?.data?.message ? e.response.data.message : "Please contact IT Team to report and resolve the error"}</p>
            </>, toastOptions);
        });
}