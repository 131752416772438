import React from "react";
import Chart from "react-apexcharts";

export default function RadialBar_ReportsStatus(props) {
    const totalProjectsForPercentage = props.reportedCount + props.reportStatus?.draft + props.reportStatus?.deleted

    const height = 320;
    const options = {
        chart: {
            height: height,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                offsetY: 0,
                startAngle: 0,
                endAngle: 270,
                hollow: {
                    margin: 20,
                    size: '25%',
                    background: 'transparent',
                    image: undefined,
                },
                dataLabels: {
                    name: {
                        show: true,
                    },
                    value: {
                        show: true,
                    },
                    total: {
                        show: true,
                        label: 'Total',
                        formatter: function () {
                            return props.reportedCount
                        }
                    }
                },
                barLabels: {
                    enabled: true,
                    useSeriesColors: true,
                    margin: 20,
                    fontSize: '16px',
                    formatter: function (seriesName, opts) {
                        return seriesName + ":  " + checkIsNotZeroForPercentageToNumber(opts.w.globals.series[opts.seriesIndex], totalProjectsForPercentage);
                    },
                },
            }
        },
        stroke: {
            lineCap: 'round'
        },
        colors: ['#775DD0', '#008FFB', '#00E396', '#FEB019', '#FF4560'],
        labels: ['Draft', 'Early', 'On Time', 'Late', 'Deleted'],
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    show: false
                }
            }
        }]
    };

    const series = [checkIfTotalIsNotZeroForNumberToPercentage(props.reportStatus?.draft, totalProjectsForPercentage),
        checkIfTotalIsNotZeroForNumberToPercentage(props.reportStatus?.early, totalProjectsForPercentage),
        checkIfTotalIsNotZeroForNumberToPercentage(props.reportStatus?.onTime, totalProjectsForPercentage),
        checkIfTotalIsNotZeroForNumberToPercentage(props.reportStatus?.late, totalProjectsForPercentage),
        checkIfTotalIsNotZeroForNumberToPercentage(props.reportStatus?.deleted, totalProjectsForPercentage)];


    return (
        <Chart
            options={options}
            series={series}
            type="radialBar"
            height={height}
        />
    )
}

function checkIfTotalIsNotZeroForNumberToPercentage(count, totalProjectsForPercentage) {
    if (totalProjectsForPercentage > 0)
        return count / totalProjectsForPercentage * 100;
    else
        return 0;
}

function checkIsNotZeroForPercentageToNumber(count, totalProjectsForPercentage) {
    if (totalProjectsForPercentage > 0)
        return count / 100 * totalProjectsForPercentage;
    else
        return 0;
}