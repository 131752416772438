import {useDispatch, useSelector} from "react-redux";
import {
    selectSelectedMemberData,
    setSelectedMemberData
} from "../../../redux/slice/membershipSlice";
import React from "react";
import {
    Avatar,
    Button,
    Card,
    CardBody,
    CardFooter,
    Divider,
    Input,
    Select,
    SelectItem,
} from "@nextui-org/react";
import {
    DD_BLOOD_GROUP, DD_DESIGNATIONS,
    DD_GENDER, DD_MEMBERSHIP_STATUS_NEW_MEMBERS,
    DD_PRANALI_ACCESS_FOR_PRES_SEC
} from "../../../../constants/dropdownList";
import {getImageFromDrive} from "../../../common/DetailsViewHelpers";

export default function CompleteMemberDetails(props) {
    const dispatch = useDispatch();
    const selectedMemberData = useSelector(selectSelectedMemberData);

    const [rotaryId, setRotaryId] = React.useState('');
    const [membershipStatus, setMembershipStatus] = React.useState("Active");
    const [isMembershipActive, setIsMembershipActive] = React.useState(true);

    const [pranaliAccess, setPranaliAccess] = React.useState([]);

    // It May Break here
    const [memberName, setMemberName] = React.useState(selectedMemberData?.basicData?.name ? selectedMemberData.basicData.name : '');
    const [primaryContact, setPrimaryContact] = React.useState(selectedMemberData?.basicData?.contact ? selectedMemberData.basicData.contact : null);
    const [secondaryContact, setSecondaryContact] = React.useState(selectedMemberData?.basicData?.contactSecondary ? selectedMemberData.basicData.contactSecondary : null);
    const [emailId, setEmailId] = React.useState(selectedMemberData?.basicData?.email ? selectedMemberData.basicData.email : '');
    const [dateOfBirth, setDateOfBirth] = React.useState(selectedMemberData?.basicData?.dateOfBirth ? selectedMemberData.basicData.dateOfBirth : '');
    const [bloodGroup, setBloodGroup] = React.useState(selectedMemberData?.basicData?.bloodGroup ? selectedMemberData.basicData.bloodGroup : '');
    const [designations, setDesignations] = React.useState([]);
    const [address, setAddress] = React.useState(selectedMemberData?.basicData?.address ? selectedMemberData.basicData.address : '');
    const [gender, setGender] = React.useState(selectedMemberData?.basicData?.gender ? selectedMemberData.basicData.gender : '');
    const [profileImageUrl, setProfileImageUrl] = React.useState(selectedMemberData?.basicData?.profileImageUrl ? selectedMemberData.basicData.profileImageUrl : "");

    const validateContactNumber = (value) => value.toString().match(/^[0-9]+$/) && value.toString().length === 10;
    const isPrimaryContactInvalid = React.useMemo(() => {
        if (null === primaryContact) return false;
        return !validateContactNumber(primaryContact);
    }, [primaryContact]);

    const isSecondaryContactInvalid = React.useMemo(() => {
        if (null === secondaryContact || undefined === secondaryContact || secondaryContact.length === 0) return false;
        return !validateContactNumber(secondaryContact);
    }, [secondaryContact]);

    const validateProfileImageUrl = (value) => value.toString().includes("https://drive.google.com/file/d/") || value.toString().includes("https://drive.google.com/uc?export=view&id=")
    const isProfileImageUrlInvalid = React.useMemo(() => {
        if (null === profileImageUrl) return false;
        return !validateProfileImageUrl(profileImageUrl);
    }, [profileImageUrl]);

    function updateMembershipStatus(e) {
        setIsMembershipActive(e.target.value === "Active");
        setMembershipStatus(e.target.value);
    }

    function keepSubmitDisabled() {
        let pranaliAccessCheck = true;
        if (isMembershipActive) {
            pranaliAccessCheck = null != pranaliAccess && pranaliAccess.length > 0;
        }

        return !(
            (null !== membershipStatus) && pranaliAccessCheck &&
            (null != memberName && memberName.length > 0) &&
            (null != primaryContact && primaryContact.toString().length > 0) &&
            (null != emailId && emailId.length > 0) &&
            (null != dateOfBirth && dateOfBirth.length > 0) &&
            (null != bloodGroup && bloodGroup.length > 0) &&
            (null != designations && designations.length > 0) &&
            (null != address && address.length > 0) &&
            (null != gender && gender.length > 0) &&
            (null != profileImageUrl && profileImageUrl.length > 0)
        )
    }

    function submitForm() {
        let arrayOfDesignations = [];
        if (designations.includes(",")) {
            arrayOfDesignations = designations.split(",");
            if (designations.includes("Training, Revival and Sustenance Chair")) {
                let indexOfTRS1 = designations.findIndex("Training");
                let indexOfTRS2 = designations.findIndex(" Revival and Sustenance Chair");
                designations.splice(indexOfTRS1, 1);
                designations.splice(indexOfTRS2, 1);
                arrayOfDesignations.push("Training, Revival and Sustenance Chair");
            }
        } else {
            arrayOfDesignations.push(designations);
        }

        let newMemberData = {
            membershipId: selectedMemberData?.membershipId,
            rotaryId: membershipStatus === "Active" ? rotaryId : null,
            membershipStatus: membershipStatus,
            pranaliAccess: membershipStatus === "Active" ? pranaliAccess : "None",
            basicData: {
                name: memberName,
                contact: primaryContact,
                contactSecondary: secondaryContact,
                email: emailId,
                dateOfBirth: dateOfBirth,
                bloodGroup: bloodGroup,
                designation: arrayOfDesignations,
                address: address,
                gender: gender,
                profileImageUrl: profileImageUrl
            }
        }

        dispatch(setSelectedMemberData(newMemberData));

        props.nextPage();
    }

    return (
        <Card className="mt-8 pt-4">
            <CardBody>
                <div className="grid grid-cols-2 sm:grid-cols-12 gap-4 items-center">
                    <div className="max-w-md col-span-2 sm:col-span-3 justify-self-center">
                        <Avatar isBordered
                                radius="lg"
                                size="lg"
                                className="w-32 h-32"
                                src={getImageFromDrive(profileImageUrl)}/>
                    </div>
                    <div className="col-span-2 sm:col-span-9">
                        <div className="grid grid-cols-2 sm:grid-cols-12 gap-4">
                            <div className="col-span-full flex flex-col">
                                <h2 className="font-semibold text-2xl">Rtr. {memberName} </h2>
                                <p className="font-light text-mdl">Date of Birth : {dateOfBirth} </p>
                            </div>
                            <Select isRequired
                                    label="Membership Status"
                                    variant="underlined"
                                    className="max-w-md col-span-2 sm:col-span-4"
                                    defaultSelectedKeys={[membershipStatus]}
                                    onChange={updateMembershipStatus}>
                                {DD_MEMBERSHIP_STATUS_NEW_MEMBERS.map(data =>
                                    <SelectItem key={data.label} value={data.value}>
                                        {data.label}
                                    </SelectItem>
                                )}
                            </Select>

                            {isMembershipActive && <Input
                                type="text"
                                variant="underlined"
                                label="Rotary International Id"
                                value={rotaryId}
                                onValueChange={setRotaryId}
                                className="max-w-md col-span-2 sm:col-span-4"
                            />}
                            {isMembershipActive && <Select
                                isRequired
                                label="Pranali Access"
                                variant="underlined"
                                defaultSelectedKeys={pranaliAccess.length > 0 ? [pranaliAccess] : []}
                                onChange={e => setPranaliAccess(e.target.value)}
                                className="max-w-md col-span-2 sm:col-span-4">
                                {DD_PRANALI_ACCESS_FOR_PRES_SEC.map(data =>
                                    <SelectItem key={data.value} value={data.value}>
                                        {data.label}
                                    </SelectItem>
                                )}
                            </Select>}
                        </div>
                    </div>
                    <Divider className="my-2 col-span-full"/>
                    <Input isRequired
                           type="number"
                           variant="underlined"
                           label="Primary Contact Number"
                           value={primaryContact}
                           onValueChange={setPrimaryContact}
                           isInvalid={isPrimaryContactInvalid}
                           errorMessage={isPrimaryContactInvalid && "Please enter a valid 10digit number"}
                           className="max-w-md col-span-2 sm:col-span-4"
                    />
                    <Input isClearable
                           type="number"
                           variant="underlined"
                           label="Secondary Contact Number"
                           value={secondaryContact}
                           onValueChange={setSecondaryContact}
                           isInvalid={isSecondaryContactInvalid}
                           errorMessage={isSecondaryContactInvalid && "Please enter a valid 10digit number"}
                           className="max-w-md col-span-2 sm:col-span-4"
                    />
                    <Input isRequired
                           type="text"
                           variant="underlined"
                           label="Email Address"
                           value={emailId}
                           onValueChange={setEmailId}
                           className="max-w-md col-span-2 sm:col-span-4"
                    />
                    <Select isRequired
                            label="Blood Group"
                            defaultSelectedKeys={bloodGroup.length > 0 ? [bloodGroup] : []}
                            variant="underlined"
                            className="max-w-md col-span-2 sm:col-span-4"
                            onChange={e => setBloodGroup(e.target.value)}>
                        {DD_BLOOD_GROUP.map(data =>
                            <SelectItem key={data.label} value={data.value}>
                                {data.label}
                            </SelectItem>
                        )}
                    </Select>
                    <Select isRequired
                            selectionMode="multiple"
                            label="Designation"
                            variant="underlined"
                            className="max-w-md col-span-2 sm:col-span-4"
                            defaultSelectedKeys={designations.length > 0 ? [...designations] : []}
                            onChange={e => setDesignations(e.target.value)}>
                        {DD_DESIGNATIONS.map(data =>
                            <SelectItem key={data.label} value={data.value}>
                                {data.label}
                            </SelectItem>
                        )}
                    </Select>
                    <Select isRequired
                            label="Gender"
                            defaultSelectedKeys={gender.length > 0 ? [gender] : []}
                            variant="underlined"
                            className="max-w-md col-span-2 sm:col-span-4"
                            onChange={e => setGender(e.target.value)}>
                        {DD_GENDER.map(data =>
                            <SelectItem key={data.label} value={data.value}>
                                {data.label}
                            </SelectItem>
                        )}
                    </Select>
                    <Input isRequired
                           type="text"
                           variant="underlined"
                           value={profileImageUrl}
                           onValueChange={setProfileImageUrl}
                           isInvalid={isProfileImageUrlInvalid}
                           errorMessage={isProfileImageUrlInvalid && "Please enter a valid google drive image url."}
                           label="Profile Image Url"
                           className="max-w-md col-span-2 sm:col-span-4"
                    />
                    <Input isRequired
                           type="text"
                           variant="underlined"
                           value={address}
                           label="Address"
                           className="max-w-lg col-span-2 sm:col-span-4"
                           onValueChange={setAddress}
                    />
                </div>
            </CardBody>
            <CardFooter>
                <div className="flex gap-4 ">
                    <div>
                        <Button color="secondary" variant="solid" onClick={() => props.prevPage()}>
                            Previous
                        </Button>
                    </div>
                    <div>
                        <Button color="success" variant="ghost"
                                isDisabled={keepSubmitDisabled()}
                                onClick={submitForm}>
                            Next
                        </Button>
                    </div>
                    <div>
                        <Button color="default" variant="bordered" onClick={() => props.closeMemberRegistration()}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </CardFooter>
        </Card>
    );
}