import {configureStore} from '@reduxjs/toolkit';
import {persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER} from "redux-persist";
import storage from "redux-persist/lib/storage";

import loggedInUserReducer from "./slice/userSlice";
import aarasReducer from "./slice/aaraSlice";
import reportingReducer from "./slice/reportingSlice";
import applicationReducer from "./slice/application57Slice";
import financeReducer from "./slice/financeSlice";
import clubDataReducer from "./slice/clubDataSlice";
import membershipReducer from "./slice/membershipSlice";
import ridReducer from "./slice/ridistrictSlice";

const persistUserReducer = persistReducer({
    key: "userRoot",
    version: 1,
    storage,
}, loggedInUserReducer);
const persistAaraReducer = persistReducer({
    key: "aaraRoot",
    version: 1,
    storage,
}, aarasReducer);
const persistReportingReducer = persistReducer({
    key: "reportsRoot",
    version: 1,
    storage: storage,
}, reportingReducer);
const persistCouncil57FormsReducer = persistReducer({
    key: "council57Root",
    version: 1,
    storage,
}, applicationReducer);

const persistFinanceReducer = persistReducer({
    key: "financeRecords",
    version: 1,
    storage,
}, financeReducer);
const persistClubDataReducer = persistReducer({
    key: "clubData",
    version: 1,
    storage,
}, clubDataReducer);
const persistMembershipDataReducer = persistReducer({
    key: "membership",
    version: 1,
    storage,
}, membershipReducer);
const persistRIDDataReducer = persistReducer({
    key: "rid",
    version: 1,
    storage,
}, ridReducer);

const store = configureStore({
    reducer: {
        user: persistUserReducer,
        aara: persistAaraReducer,
        reports: persistReportingReducer,
        council57: persistCouncil57FormsReducer,
        financeRecords: persistFinanceReducer,
        clubData: persistClubDataReducer,
        membership: persistMembershipDataReducer,
        rid: persistRIDDataReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
    devTools: process.env.NODE_ENV !== 'production',
});
export let persistor = persistStore(store);
export default store;
