import {createSlice} from '@reduxjs/toolkit';

const initialCouncil57Applications = {
    applicants: [],
    selectedApplicant: null,
    status: 'idle',
}

const applicationsSlice = createSlice({
    name: "council57",
    initialState: initialCouncil57Applications,
    reducers: {
        set57Status: (state, action) => {
            state.status = action.payload;
        },
        setApplications: (state, action) => {
            state.applicants = action.payload;
        },
        setSelectedApplicant:(state,action)=>{
            state.selectedApplicant = action.payload;
        },
        setAllApplicantsDataNull: (state) => {
            state.applicants = [];
            state.status = 'idle';
            state.selectedApplicant = null;
        }
    }
});

export const {
    set57Status,
    setApplications,
    setSelectedApplicant,
    setAllApplicantsDataNull
} = applicationsSlice.actions;

export const selectAllApplications = (state) => state.council57.applicants;
export const selectFetchStatus = (state) => state.council57.status;
export const selectSelectedApplicant = (state) => state.council57.selectedApplicant

export default applicationsSlice.reducer;