import {useDispatch, useSelector} from "react-redux";
import {
    getSelectedRecordType,
    getSelectedReportStatus,
    selectSelectedReport,
    setSelectedReport,
    setSelectedReportStatus
} from "../../redux/slice/reportingSlice";
import {
    Avatar,
    AvatarGroup, Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Chip,
    Divider,
    Skeleton,
    Tooltip
} from "@nextui-org/react";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {COLOR_MAP_IS_AMBASSADORIAL_TYPE} from "../../common/TableCommonMethodsAndHelpers";
import {getImageFromDrive} from "../../common/DetailsViewHelpers";
import {TagIcon} from "../../Icons/Icons";

export default function ViewReportAmbassadorial() {
    const dispatch = useDispatch();
    const selectedReport = useSelector(selectSelectedReport);
    const selectedReportStatus = useSelector(getSelectedReportStatus);
    const selectedRecordType = useSelector(getSelectedRecordType);

    const navigate = useNavigate();

    function closeProjectReport() {
        dispatch(setSelectedReport({
            report: null,
            reportType: null
        }));
        dispatch(setSelectedReportStatus("idle"));
        window.opener = null;
        window.open("", "_self");
        window.close();
    }

    useEffect(() => {
        if (selectedRecordType !== "Ambassadorial" && selectedReportStatus === "complete") {
            window.opener = null;
            window.open("", "_self");
            window.close();
        } else if (window.location.pathname.toString().includes("/view-report") && (null === selectedReport || undefined === selectedReport)) {
            window.opener = null;
            window.open("", "_self");
            window.close();
        }
    }, []);

    return (<Card>
            <CardHeader className="flex gap-3">
                <div className="flex flex-col">
                    <Skeleton isLoaded={'complete' === selectedReportStatus} className="rounded-lg">
                        <p className="text-md">{selectedReport?.eventName}</p>
                    </Skeleton>
                    <Skeleton isLoaded={'complete' === selectedReportStatus} className="rounded-lg">
                        <p className="text-small text-default-500">
                            By {selectedReport?.eventBy}
                        </p>
                    </Skeleton>
                    <Skeleton isLoaded={'complete' === selectedReportStatus} className="rounded-lg">
                        <p className="text-xs text-default-500 mt-3">
                            <Chip className="capitalize px-4 mr-2"
                                  color="primary"
                                  size="sm"
                                  variant="flat"
                                  startContent={<TagIcon size={28}/>}
                            >
                                {selectedReport?.ambassadorialId}
                            </Chip>
                            <Chip className="capitalize px-4 ml-2"
                                  color={COLOR_MAP_IS_AMBASSADORIAL_TYPE[selectedReport?.ambassadorialType]}
                                  size="sm"
                                  variant="flat"
                                  startContent={<TagIcon size={28}/>}>
                                {selectedReport?.ambassadorialType}
                            </Chip>
                            {selectedReport?.eventId && <Chip className="capitalize px-4 ml-2"
                                                              color={COLOR_MAP_IS_AMBASSADORIAL_TYPE[selectedReport?.ambassadorialType]}
                                                              size="sm"
                                                              variant="flat"
                                                              startContent={<TagIcon size={28}/>}>
                                {selectedReport?.eventId}
                            </Chip>}
                        </p>
                    </Skeleton>
                </div>
            </CardHeader>
            <Divider/>
            <Skeleton isLoaded={'complete' === selectedReportStatus} className="rounded-lg">
                <CardBody>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 items-center">
                        <div className="flex flex-col">
                            <p className="text-md">Club Name</p>
                            <p className="text-small text-default-500">{selectedReport?.adminData?.clubName}</p>
                        </div>
                        <div className="flex flex-col">
                            <p className="text-md">Reporting Month</p>
                            <p className="text-small text-default-500">{selectedReport?.adminData?.reportingMonth}</p>
                        </div>
                        {selectedReport?.adminData?.reportingQuarter &&
                            <div className="flex flex-col">
                                <p className="text-md">Reporting Quarter</p>
                                <p className="text-small text-default-500">{selectedReport?.adminData?.reportingQuarter}</p>
                            </div>}
                        <div className="flex flex-col">
                            <p className="text-md">Report Status</p>
                            <p className="text-small text-default-500">{selectedReport?.adminData?.reportStatus}</p>
                        </div>

                        <div className="flex flex-col col-span-full">
                            <p className="text-md">To view other supporting documents&nbsp;
                                <a className="underline decoration-indigo-500"
                                   href={selectedReport?.supportDocumentsLink}>
                                    click here
                                </a>.
                            </p>
                        </div>
                        {selectedReport?.ambassadorialType === "PIS" && <div className="flex flex-col col-span-full">
                            <p className="text-md">Report Summary</p>
                            <p className="text-small text-default-500">{selectedReport?.reportSummary}</p>
                        </div>}
                        <div className="flex flex-col col-span-full">
                            <p className="text-md">Attendance</p>
                            {/*<div className="flex flex-row gap-2">
                                {selectedReport?.membersAttendanceInfo.map(data =>
                                    <Tooltip content={
                                        <div className="px-1 py-2">
                                            <div className="text-small font-bold">{data.name}</div>
                                            <div className="text-tiny">{data.pranaliId}</div>
                                        </div>
                                    }>
                                        <Chip
                                            variant="flat"
                                            color="secondary"
                                            size="lg"
                                            avatar={<Avatar
                                                size="lg"
                                                name={data.name}
                                                getInitials={(name) => name.charAt(0)}
                                                src={getImageFromDrive(data.profilePhotoUrl)}/>
                                            }>
                                            {data.name}
                                        </Chip>
                                    </Tooltip>)}
                            </div>*/}

                            <AvatarGroup isBordered className="justify-start px-4 mt-3">
                                {selectedReport?.membersAttendanceInfo.map(data =>
                                    <Tooltip
                                        placement="bottom"
                                        content={<div className="px-1 py-2">
                                            <div className="text-small font-bold">{data.name}</div>
                                            <div className="text-tiny">{data.pranaliId}</div>
                                        </div>}>
                                        <Avatar src={getImageFromDrive(data.profilePhotoUrl)}/>
                                    </Tooltip>)}
                            </AvatarGroup>
                        </div>
                    </div>
                </CardBody>
            </Skeleton>
            <CardFooter>
                <Button type="button" color="default" variant="faded" onClick={() => closeProjectReport()}>
                    Close
                </Button>
            </CardFooter>
        </Card>
    );
}