import {createSlice} from '@reduxjs/toolkit';

const initialUserState = {
    credentials: null,
}

const userSlice = createSlice({
    name: "user",
    initialState: initialUserState,
    reducers: {
        setUser: (state, action) => {
            return action.payload;
        },
        setUserCredentials: (state, action) => {
            state.credentials = action.payload;
        },
        setAdminDuesPayment: (state, action) => {
            state.isAdminDuesPaid = action.payload;
        },
        setPranaliAccess: (state, action) => {
            return {...state, pranaliAccess: action.payload}
        }
    }
});

export const selectAllUserData = (state) => state.user;

export const {
    setUser,
    setUserCredentials,
    setAdminDuesPayment,
    setPranaliAccess
} = userSlice.actions;

export default userSlice.reducer;