export const preferenceOptions = [
    {name: "Team Secretariat", uid: "Team Secretariat"},
    {name: "Team Finance", uid: "Team Finance"},
    {name: "Team Training, Revival & Sustenance", uid: "Team Training, Revival & Sustenance"},
    {name: "Team Zones", uid: "Team Zones"},
    {name: "Team Club Service", uid: "Team Club Service"},
    {name: "Team Community Service", uid: "Team Community Service"},
    {name: "Team Career Development", uid: "Team Career Development"},
    {name: "Team International Service", uid: "Team International Service"},
    {name: "Team Sports", uid: "Team Sports"},
    {name: "Team PR & Marketing", uid: "Team PR & Marketing"},
    {name: "Team PIS", uid: "Team PIS"},
    {name: "Team Admin", uid: "Team Admin"},
    {name: "Team Events", uid: "Team Events"},
    {name: "Team Thrust Areas", uid: "Team Thrust Areas"},
    {name: "Team Editorial", uid: "Team Editorial"},
    {name: "Team Graphic Designing", uid: "Team Graphic Designing"},
    {name: "Team Photography & Videography", uid: "Team Photography & Videography"},
    {name: "Team Motion Graphics", uid: "Team Motion Graphics"},
    {name: "Team Social Media", uid: "Team Social Media"},
    {name: "Team Technology", uid: "Team Technology"},
];

export const council57FormColumns = [
    {name: "APPLICATION ID", uid: "applicationId", sortable: true},
    {name: "SUBMITTED ON", uid: "timestamp", sortable: true},
    {name: "APPLICANT NAME", uid: "basicDetails.name", sortable: true},
    {name: "BELONGS TO", uid: "rotaractDetails.homeClub", sortable: true},
    {name: "ZONE", uid: "rotaractDetails.zone", sortable: true},
    {name: "PRANALI ID", uid: "rotaractDetails.pranaliId", sortable: true},
    {name: "PREFERENCE 1", uid: "preferences[0]", sortable: true},
    {name: "PREFERENCE 2", uid: "preferences[1]", sortable: true},
    {name: "PREFERENCE 3", uid: "preferences[2]", sortable: true},
];