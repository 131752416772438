import {createSlice} from '@reduxjs/toolkit';

const initialRIDDataState = {
    data: null,
    fetchDataStatus: 'idle',
    updateDataStatus: 'idle',
    updateAwardsDataStatus: 'idle',
    updateReportDeadlinesDataStatus: 'idle'
};

const ridSlice = createSlice({
    name: "rid",
    initialState: initialRIDDataState,
    reducers: {
        setRIDData: (state, action) => {
            state.data = action.payload;
        },
        setFetchStatus: (state, action) => {
            state.fetchDataStatus = action.payload;
        },
        setUpdateDataStatus: (state, action) => {
            state.updateDataStatus = action.payload;
        },
        setUpdateAwardsDataStatus: (state, action) => {
            state.updateAwardsDataStatus = action.payload;
        },
        setUpdateReportDeadlinesDataStatus: (state, action) => {
            state.updateReportDeadlinesDataStatus = action.payload;
        },
        clearRIDData: (state, action) => {
            state.data = null;
            state.fetchDataStatus = 'idle';
            state.updateDataStatus = 'idle';
            state.updateAwardsDataStatus = 'idle';
            state.updateReportDeadlinesDataStatus = 'idle';
        }

    }
});

export const selectRIDData = (state) => state.rid.data;
export const getFetchDataStatus = (state) => state.rid.fetchDataStatus;
export const getUpdateDataStatus = (state) => state.rid.updateDataStatus;
export const getUpdateAwardsDataStatus = (state) => state.rid.updateAwardsDataStatus;
export const getUpdateReportDeadlinesDataStatus = (state) => state.rid.updateReportDeadlinesDataStatus;

export const {
    setRIDData,
    setFetchStatus,
    setUpdateDataStatus,
    setUpdateAwardsDataStatus,
    setUpdateReportDeadlinesDataStatus,
    clearRIDData,
} = ridSlice.actions;

export default ridSlice.reducer;