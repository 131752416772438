import {useSelector} from "react-redux";
import {selectAllNominations} from "../../redux/slice/aaraSlice";
import React from "react";
import {COLUMNS_AARA_DISTRICT_VIEW} from "../../../constants/tableColumns";
import {DD_AVENUES_LIST} from "../../../constants/dropdownList";
import {
    Button,
    Chip,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
    Input,
    Pagination, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow
} from "@nextui-org/react";
import {SearchIcon} from "../../Icons/SearchIcon";
import {ChevronDownIcon} from "../../Icons/ChevronDownIcon";
import {capitalize} from "../Finances/utils";
import {NOMINATION_STATUS_SUBMITTED} from "../../../constants/WebPageConstants";
import {OPTIONS_NOMINATION_TYPES} from "../../../constants/tableFilterOptions";

export default function ViewNominationsProjectsWise() {
    const aaras = useSelector(selectAllNominations);
    let filteredProjectNominations = [];

    aaras.forEach(nomination => {
        if (nomination.adminData.status === NOMINATION_STATUS_SUBMITTED) {
            // if (nomination.adminData.status === "SUBMITTED") {
            let list = nomination.projectNominations.map(project => ({
                    ...project,
                    clubName: nomination.clubName,
                    nominationType: nomination.nominationType,
                })
            );
            filteredProjectNominations.push(...list);
        }
    })

    const INITIAL_VISIBLE_COLUMNS = ["clubName", "nominationType", "projectId", "projectName", "nominationFor"];
    const [filterValue, setFilterValue] = React.useState("");
    const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
    const [visibleColumns, setVisibleColumns] = React.useState(new Set(INITIAL_VISIBLE_COLUMNS));
    const [nominationForFilter, setNominationForFilter] = React.useState("all");
    const [nominationTypeFilter, setNominationTypeFilter] = React.useState("all");
    const [rowsPerPage, setRowsPerPage] = React.useState(15);
    const [sortDescriptor, setSortDescriptor] = React.useState({
        column: "projectId",
        direction: "ascending",
    });
    const [page, setPage] = React.useState(1);

    const hasSearchFilter = Boolean(filterValue);

    const headerColumns = React.useMemo(() => {
        if (visibleColumns === "all") return COLUMNS_AARA_DISTRICT_VIEW;

        return COLUMNS_AARA_DISTRICT_VIEW.filter((column) => Array.from(visibleColumns).includes(column.uid));
    }, [visibleColumns]);

    const filteredItems = React.useMemo(() => {
        let filteredRecords = [...filteredProjectNominations];

        if (hasSearchFilter) {
            filteredRecords = filteredRecords.filter((records) =>
                records.projectId.toLowerCase().includes(filterValue.toLowerCase()),
            );
        }
        if (nominationForFilter !== "all" && Array.from(nominationForFilter).length !== DD_AVENUES_LIST.length) {
            filteredRecords = filteredRecords.filter((records) =>
                Array.from(nominationForFilter).includes(records.nominationFor),
            );
        }
        if (nominationTypeFilter !== "all" && Array.from(nominationTypeFilter).length !== OPTIONS_NOMINATION_TYPES.length) {
            filteredRecords = filteredRecords.filter((records) =>
                Array.from(nominationTypeFilter).includes(records.nominationType),
            );
        }

        return filteredRecords;
    }, [filteredProjectNominations, filterValue, nominationTypeFilter, nominationForFilter]);

    const pages = Math.ceil(filteredItems.length / rowsPerPage);

    const items = React.useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);

    const sortedItems = React.useMemo(() => {
        return [...items].sort((a, b) => {
            const first = a[sortDescriptor.column];
            const second = b[sortDescriptor.column];
            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, items]);

    const renderCell = React.useCallback((record, columnKey) => {
        const keyArray = columnKey.split(".");
        let cellValue;
        keyArray.length > 1
            ? cellValue = record[keyArray[0]][keyArray[1]]
            : cellValue = record[columnKey];

        switch (columnKey) {
            case "nominationType" :
                return (
                    <Chip
                        classNames={{
                            base: `${record.nominationType === "Quarter 1"
                                ? 'bg-pink-100'
                                : record.nominationType === "Quarter 2"
                                    ? 'bg-fuchsia-100'
                                    : record.nominationType === "Quarter 3"
                                        ? 'bg-cyan-100'
                                        : record.nominationType === "Quarter 4"
                                            ? 'bg-green-100'
                                            : record.nominationType === "Yearly"
                                                ? 'bg-indigo-100'
                                                : record.nominationType === "On Going"
                                                    ? 'bg-amber-100'
                                                    : null}`,
                            content: `${record.nominationType === "Quarter 1"
                                ? 'text-pink-700'
                                : record.nominationType === "Quarter 2"
                                    ? 'text-fuchsia-700'
                                    : record.nominationType === "Quarter 3"
                                        ? 'text-cyan-700'
                                        : record.nominationType === "Quarter 4"
                                            ? 'text-green-700'
                                            : record.nominationType === "Yearly"
                                                ? 'text-indigo-700'
                                                : record.nominationType === "On Going"
                                                    ? 'text-amber-700'
                                                    : null}`,
                        }}
                        size="sm"
                        variant="flat">
                        {cellValue}
                    </Chip>
                );
            default:
                return cellValue;
        }
    }, []);

    const onNextPage = React.useCallback(() => {
        if (page < pages) {
            setPage(page + 1);
        }
    }, [page, pages]);

    const onPreviousPage = React.useCallback(() => {
        if (page > 1) {
            setPage(page - 1);
        }
    }, [page]);

    const onRowsPerPageChange = React.useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, []);

    const onSearchChange = React.useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = React.useCallback(() => {
        setFilterValue("")
        setPage(1)
    }, []);

    const topContent = React.useMemo(() => {
        return (
            <div className="flex flex-col gap-4">
                <div className="flex justify-between gap-3 items-end">
                    <Input
                        isClearable
                        className="w-full sm:max-w-[44%]"
                        placeholder="Search by project id on this page"
                        startContent={<SearchIcon/>}
                        value={filterValue}
                        onClear={() => onClear()}
                        onValueChange={onSearchChange}
                    />
                    <div className="flex gap-3">
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<ChevronDownIcon className="text-small"/>} color="primary"
                                        variant="ghost">
                                    Nomination For
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Nomination Type Columns"
                                closeOnSelect={false}
                                selectedKeys={nominationForFilter}
                                selectionMode="multiple"
                                onSelectionChange={setNominationForFilter}>
                                {DD_AVENUES_LIST.map((types) => (
                                    <DropdownItem key={types.label} className="capitalize">
                                        {capitalize(types.value)}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown>
                            <DropdownTrigger className="flex">
                                <Button endContent={<ChevronDownIcon className="text-small"/>} color="secondary"
                                        variant="ghost">
                                    Nomination Type
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Nomination Status Columns"
                                closeOnSelect={false}
                                selectedKeys={nominationTypeFilter}
                                selectionMode="multiple"
                                onSelectionChange={setNominationTypeFilter}>
                                {OPTIONS_NOMINATION_TYPES.map((types) => (
                                    <DropdownItem key={types.uid} className="capitalize">
                                        {capitalize(types.name)}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<ChevronDownIcon className="text-small"/>} variant="flat">
                                    Columns
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={visibleColumns}
                                selectionMode="multiple"
                                onSelectionChange={setVisibleColumns}
                            >
                                {COLUMNS_AARA_DISTRICT_VIEW.map((column) => (
                                    <DropdownItem key={column.uid} className="capitalize">
                                        {capitalize(column.name)}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                <div className="flex justify-between items-center">
                    <span
                        className="text-default-400 text-small">Total {filteredProjectNominations.length} records</span>
                    <label className="flex items-center text-default-400 text-small">
                        Rows per page:
                        <select
                            className="bg-transparent outline-none text-default-400 text-small"
                            onChange={onRowsPerPageChange}>
                            <option value="15">15</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                        </select>
                    </label>
                </div>
            </div>
        );
    }, [
        filterValue,
        nominationTypeFilter,
        nominationForFilter,
        visibleColumns,
        onRowsPerPageChange,
        filteredProjectNominations.length,
        onSearchChange,
        hasSearchFilter,
    ]);

    const bottomContent = React.useMemo(() => {
        return (
            <div className="py-2 px-2 flex justify-between items-center">
                <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="primary"
                    page={page}
                    total={pages}
                    onChange={setPage}
                />
                <div className="hidden sm:flex w-[30%] justify-end gap-2">
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onPreviousPage}>
                        Previous
                    </Button>
                    <Button isDisabled={pages === 1} size="sm" variant="flat" onPress={onNextPage}>
                        Next
                    </Button>
                </div>
            </div>
        );
    }, [selectedKeys, items.length, page, pages, hasSearchFilter]);

    return (
        <Table
            aria-label="Example table with custom cells, pagination and sorting"
            isHeaderSticky
            bottomContent={bottomContent}
            bottomContentPlacement="outside"
            classNames={{
                wrapper: "max-h-[582px]",
            }}
            selectedKeys={selectedKeys}
            sortDescriptor={sortDescriptor}
            topContent={topContent}
            topContentPlacement="outside"
            onSelectionChange={setSelectedKeys}
            onSortChange={setSortDescriptor}>
            <TableHeader columns={headerColumns}>
                {(column) => (
                    <TableColumn
                        key={column.uid}
                        align={column.uid === "actions" ? "center" : "start"}
                        allowsSorting={column.sortable}>
                        {column.name}
                    </TableColumn>
                )}
            </TableHeader>
            <TableBody emptyContent={"No record found"} items={sortedItems}>
                {item => <TableRow key={item.projectId}>
                    {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                </TableRow>}
            </TableBody>
        </Table>
    );
}