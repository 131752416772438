import {createSlice} from '@reduxjs/toolkit';

const initialFinanceRecords = {
    records: [],
    selectedRecord: null,
    status: 'idle',
    selectedRecordStatus: 'idle',
    updateRecordStatus: 'idle',
}

const financeSlice = createSlice({
    name: "financeRecords",
    initialState: initialFinanceRecords,
    reducers: {
        setFinanceRecordsStatus: (state, action) => {
            state.status = action.payload;
        },
        setRecords: (state, action) => {
            state.records = action.payload;
        },
        setSelectedFinanceRecord: (state, action) => {
            state.selectedRecord = action.payload;
        },
        setSelectedFinanceRecordStatus: (state, action) => {
            state.selectedRecordStatus = action.payload;
        },
        setUpdateRecordStatus: (state, action) => {
            state.updateRecordStatus = action.payload;
        },
        updateRecords: (state, action) => {
            state.records = state.records.with(action.payload.recordIndex, action.payload.updatedRecord);
        },
        setAllFinanceDataNull: (state) => {
            state.records = [];
            state.status = 'idle';
            state.selectedRecordStatus = 'idle';
            state.selectedRecord = null;
            state.updateRecordStatus = 'idle';
        }
    }
});

export const {
    setFinanceRecordsStatus,
    setRecords,
    setSelectedFinanceRecord,
    setSelectedFinanceRecordStatus,
    setUpdateRecordStatus,
    updateRecords,
    setAllFinanceDataNull
} = financeSlice.actions;

export const selectAllFinanceRecords = (state) => state.financeRecords.records;
export const selectRecordsFetchStatus = (state) => state.financeRecords.status;
export const selectSelectedRecord = (state) => state.financeRecords.selectedRecord;
export const selectFinanceRecordStatus = (state) => state.financeRecords.selectedRecordStatus;
export const selectUpdateRecordStatus = (state) => state.financeRecords.updateRecordStatus;

export default financeSlice.reducer;