import ViewMemberData from "../ViewMemberData";
import React from "react";
import {Button, Card, CardBody, CardFooter} from "@nextui-org/react";
import {ToastContainer} from "react-toastify";
import {
    selectSelectedMemberData, getEditedMembershipDataStatus,
} from "../../../redux/slice/membershipSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectAllUserData} from "../../../redux/slice/userSlice";
import {useNavigate} from "react-router-dom";
import {confirmMemberRegistration} from "../../../../services/MembershipService";

export default function ConfirmMembership(props) {
    const dispatch = useDispatch();
    const user = useSelector(selectAllUserData);
    const selectedMemberData = useSelector(selectSelectedMemberData);
    const navigate = useNavigate();

    const editedMembershipDataStatus = useSelector(getEditedMembershipDataStatus);

    return (
        <Card className="mt-10">
            <ToastContainer/>
            <CardBody>
                <ViewMemberData/>
            </CardBody>
            <CardFooter>
                <div className="flex gap-4 ">
                    <div>
                        <Button color="secondary" variant="solid" onClick={() => props.prevPage()}>
                            Previous
                        </Button>
                    </div>
                    <div>
                        <Button color="success" variant="ghost"
                                isLoading={editedMembershipDataStatus === "pending"}
                                onClick={() => confirmMemberRegistration(dispatch, user, selectedMemberData, navigate)}>
                            Submit
                        </Button>
                    </div>
                    <div>
                        <Button color="default" variant="bordered" onClick={() => props.closeMemberRegistration()}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </CardFooter>
        </Card>
    );
}