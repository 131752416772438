import React, {useState} from 'react';
import Stepper from 'react-stepper-horizontal';
import SearchMember from "./SearchMember";
import ConfirmMembership from "./ConfirmMembership";
import CompleteMemberDetails from "./CompleteMemberDetails";
import {useNavigate} from "react-router-dom";
import {
    setEditedMembershipDataStatus,
    setSearchedMembersData,
    setSearchedMembersDataStatus,
    setSelectedMemberData
} from "../../../redux/slice/membershipSlice";
import {useDispatch} from "react-redux";


export default function MembershipStepper() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [page, setPage] = useState(0);

    function nextPage() {
        setPage(page + 1);
    }

    function prevPage() {
        setPage(page - 1);
    }

    function closeMemberRegistration() {
        dispatch(setSearchedMembersData([]));
        dispatch(setSearchedMembersDataStatus("idle"));
        dispatch(setSelectedMemberData(null));
        dispatch(setEditedMembershipDataStatus("idle"));
        navigate("..")
    }

    const steps = [
        {title: "Search Member"},
        {title: "Complete Registration"},
        {title: "Confirm Details"},
    ];

    return (
        <>
            <Stepper steps={steps} activeStep={page}/>
            {page === 0 && <SearchMember nextPage={nextPage} closeMemberRegistration={closeMemberRegistration}/>}
            {page === 1 && <CompleteMemberDetails nextPage={nextPage} prevPage={prevPage}
                                                  closeMemberRegistration={closeMemberRegistration}/>}
            {page === 2 && <ConfirmMembership prevPage={prevPage} closeMemberRegistration={closeMemberRegistration}/>}
        </>
    )
}