export const COLOR_MAP_REPORT_STATUS = {
    Draft: "secondary",
    Early: "primary",
    OnTime: "success",
    Late: "warning",
    Deleted: "danger"
};

export const COLOR_MAP_MEMBERSHIP_STATUS = {
    Active: "success",
    Guest: "secondary",
    Alumni: "warning",
    Left: "default",
    Terminated: "danger"
};

export const COLOR_MAP_IS_PIS_INTERACTION = {
    true: "warning",
    false: "secondary",
};
export const COLOR_MAP_IS_AMBASSADORIAL_TYPE = {
    Project: "success",
    Meeting: "warning",
    PIS: "secondary",
    LetterHead: "primary"
};