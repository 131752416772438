import {Card, CardHeader, CardFooter, Image, Button} from "@nextui-org/react";
import CafeZoi from "../../../assets/images/priviledgeCard/CafeZoi.png";
import FFC from "../../../assets/images/priviledgeCard/Ffc.png";

export default function EdgeCard() {
    return (
        <div className="gap-4 grid grid-cols-12 grid-rows-2 px-2 sm:px-8">
            <Card isFooterBlurred className="col-span-12 sm:col-span-6 lg:col-span-4 h-[300px]">
                <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                    <h4 className="text-black font-medium text-large">25% Off</h4>
                    <p className="text-tiny text-black/60 uppercase font-bold">With code "Rotaract25"!</p>
                </CardHeader>
                <Image
                    removeWrapper
                    alt="Card background"
                    className="z-0 w-full h-full -translate-y-6 -translate-x-3 object-contain"
                    src="https://m.media-amazon.com/images/S/al-eu-726f4d26-7fdb/c53b50d7-f5df-49d7-8220-a4ab6165a0b2.jpeg"
                />
                <CardFooter
                    className="absolute bg-black/30 border-t-1 border-zinc-100/50 justify-between before:rounded-xl rounded-large bottom-1 w-[calc(100%_-_8px)] shadow-small ml-1 z-10">
                    <div>
                        <p className="text-black text-tiny">The one-stop shop for the trendiest menswear and
                            apparel. </p>
                        <p className="text-black text-tiny">Look your best and feel your most confident self, without
                            breaking the bank!</p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <Button className="text-tiny" color="primary" radius="lg" size="sm">
                            Shop here
                        </Button>
                    </div>
                </CardFooter>
            </Card>
            <Card isFooterBlurred className="col-span-12 sm:col-span-6 lg:col-span-4 h-[300px]">
                <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                    <h4 className="text-black font-medium text-large">Flat 15% Off</h4>
                    {/*<p className="text-tiny text-black/60 uppercase font-bold">SUB TITLE</p>*/}
                </CardHeader>
                <Image
                    removeWrapper
                    alt="Card background"
                    className="z-0 w-full h-full -translate-y-6 -translate-x-3 object-contain"
                    src={CafeZoi}
                />
                <CardFooter
                    className="absolute bg-black/30 border-t-1 border-zinc-100/50 justify-between before:rounded-xl rounded-large bottom-1 w-[calc(100%_-_8px)] shadow-small ml-1 z-10">
                    <div>
                        <p className="text-black text-tiny">Cafe Zoi specialises in its scrumptious Burgers, Sandwiches,
                            Pizza and more</p>
                        <p className="text-black text-tiny">Perfect for an enjoyable evening with your pals or the
                            memorable date you're planning for!</p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <Button className="text-tiny" color="primary" radius="lg" size="sm">Get Location</Button>
                        <Button radius="full" size="sm">Contact</Button>
                    </div>
                </CardFooter>
            </Card>
            <Card isFooterBlurred className="col-span-12 sm:col-span-6 lg:col-span-4 h-[300px]">
                <CardHeader className="absolute z-10 top-1 flex-col !items-start">
                    <h4 className="text-black font-medium text-large">Rs. 500/- Off</h4>
                    <p className="text-tiny text-black/60 uppercase font-bold">On minimum spend of Rs. 2000/-</p>
                </CardHeader>
                <Image
                    removeWrapper
                    alt="Card background"
                    className="z-0 w-full h-full -translate-y-6 -translate-x-3 object-contain"
                    src="https://www.mangostationery.com/cdn/shop/files/Mango_Logo_Yellow_9e13e372-b446-4ed5-9c11-99578af1509f_1204x630.png?v=1639543287"
                />
                <CardFooter
                    className="absolute bg-black/30 border-t-1 border-zinc-100/50 justify-between before:rounded-xl rounded-large bottom-1 w-[calc(100%_-_8px)] shadow-small ml-1 z-10">
                    <div>
                        <p className="text-black text-tiny">Arts, Stationery, Decorations and Gifts, Mango has got you
                            all covered!</p>
                        <p className="text-black text-tiny">The perfect store to make your special moments a bit more
                            special. Don't forget to collect vouchers from District Team PRM and avail attractive
                            discounts.</p>
                    </div>
                </CardFooter>
            </Card>
            <Card isFooterBlurred className="col-span-12 md:col-span-6 h-[300px] w-full">
                <CardHeader className="absolute z-10 top-1 flex-col items-start">
                    {/*<p className="text-tiny text-white/60 uppercase font-bold">Flat 15% off.</p>*/}
                    <h4 className="text-white font-medium text-2xl">Flat 15% off.</h4>
                </CardHeader>
                <Image
                    removeWrapper
                    alt="Card example background"
                    className="z-0 w-full h-full scale-100 object-cover"
                    src={FFC}/>
                <CardFooter
                    className="absolute bg-white/40 border-0 border-zinc-100/50 justify-between before:rounded-xl rounded-large bottom-1 w-[calc(100%_-_8px)] shadow-small ml-1 z-10">
                    <div>
                        <p className="text-white text-tiny">
                            Delicious, Ambient and Affordable; FFC has got you covered!</p>
                        <p className="text-white text-tiny">
                            Treat yourself and your friends to a delightful meal at Thane's most talked about cafe!</p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <Button className="text-tiny" color="primary" radius="lg" size="sm">
                            Get Location
                        </Button>
                    </div>
                </CardFooter>
            </Card>
        </div>
    );
}