import React, {useEffect} from "react";
import {
    ModalHeader,
    ModalBody,
    ModalFooter, User, Button, Skeleton, SelectItem, Select, Input
} from "@nextui-org/react";
import {useDispatch, useSelector} from "react-redux";
import {
    selectFinanceRecordStatus,
    selectSelectedRecord, selectUpdateRecordStatus
} from "../../redux/slice/financeSlice";
import {DD_PAYMENT_STATUS} from "../../../constants/dropdownList";
import {updateOrderStatus} from "../../../services/FinanceServices";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {FINANCE_AND_IT_TEAM} from "../../../constants/WebPageConstants";
import {getImageFromDrive} from "../../common/DetailsViewHelpers";

export default function TransactionDetails(props) {
    const transactionData = useSelector(selectSelectedRecord);
    const status = useSelector(selectFinanceRecordStatus);
    const user = useSelector(selectAllUserData);
    const dispatch = useDispatch();
    const updatedRecordStatus = useSelector(selectUpdateRecordStatus);

    const [isLoaded, setIsLoaded] = React.useState(false);
    const [isEditEnabled, setIsEditEnabled] = React.useState(false);
    const [paymentStatus, setPaymentStatus] = React.useState('');
    const [updateMessage, setUpdateMessage] = React.useState('');

    const toggleLoad = () => {
        'complete' === status ? setIsLoaded(true) : setIsLoaded(false);
    };

    useEffect(() => {
        toggleLoad();
    });

    const updateRecordForm = () => {
        return (
            <div className="grid grid-cols-3 gap-4">
                <Select isRequired
                        label="Payment Status"
                        defaultSelectedKeys={paymentStatus.length > 0 ? [paymentStatus] : []}
                        variant="underlined"
                        className="max-w-md col-span-full md:col-span-1"
                        onChange={e => setPaymentStatus(e.target.value)}>
                    {DD_PAYMENT_STATUS.map(data =>
                        <SelectItem key={data.value} value={data.value}>
                            {data.label}
                        </SelectItem>
                    )}
                </Select>
                <Input isRequired
                       type="text"
                       variant="underlined"
                       value={updateMessage}
                       label="Comments"
                       className="max-w-md col-span-full md:col-span-2"
                       onValueChange={setUpdateMessage}
                />
            </div>
        )
    }

    return (
        <>
            <ModalHeader className="flex flex-col gap-1">
                <Skeleton isLoaded={isLoaded} className="w-4/5 rounded-lg">
                    Transaction Id : {transactionData?.transactionId}
                </Skeleton>
            </ModalHeader>

            <ModalBody className="grid grid-cols-2 gap-4">
                <Skeleton isLoaded={isLoaded} className="rounded-lg">
                    <div className="flex flex-col">
                        <p className="text-md">Club Name</p>
                        <p className="text-small text-default-500">{transactionData?.clubName}</p>
                    </div>
                </Skeleton>

                <Skeleton isLoaded={isLoaded} className="rounded-lg">
                    <div className="flex flex-col">
                        <p className="text-md">Transaction Date</p>
                        <p className="text-small text-default-500">{new Date(transactionData?.timestamp.replaceAll("T", " ")).toLocaleString()}</p>
                    </div>
                </Skeleton>

                <Skeleton isLoaded={isLoaded} className="rounded-lg">
                    <div className="flex flex-col">
                        <p className="text-md">Amount</p>
                        <p className="text-small text-default-500">{new Intl.NumberFormat('en-IN', {maximumSignificantDigits: 3}).format(transactionData?.amount)}
                            <span
                                className={`text-xs ${transactionData?.transactionType === "Debit" ? "text-danger" : "text-success"}`}>
                            {transactionData?.transactionType === "Debit" ? " Dr" : " Cr"}
                        </span>
                        </p>
                    </div>
                </Skeleton>

                {
                    transactionData?.transactionDoneFor && <div className="flex flex-col">
                        <p className="text-md">Transaction For</p>
                        <p className="text-small text-default-500">{transactionData.transactionDoneFor}</p>
                    </div>
                }

                <Skeleton isLoaded={isLoaded} className="rounded-lg">
                    <div className="flex flex-col">
                        <p className="text-md">Transaction Status</p>
                        <p className="text-small text-default-500">{transactionData?.paymentStatus}</p>
                    </div>
                </Skeleton>

                <Skeleton isLoaded={isLoaded} className="rounded-lg">
                    <div className="flex flex-col col-span-2">
                        <p className="text-md">Transaction Done By</p>
                        <div className="justify-start">
                            <User
                                name={transactionData?.transactionDoneBy?.name}
                                description={transactionData?.transactionDoneBy?.emailId}
                                avatarProps={{
                                    src: getImageFromDrive(transactionData?.transactionDoneBy?.profileImageUrl)
                                }}
                            />
                        </div>
                    </div>
                </Skeleton>
                {
                    null != transactionData?.successDetails &&
                    <>
                        <hr className="col-span-full"/>
                        {
                            null != transactionData?.successDetails?.razorpay_payment_id &&
                            <div className="flex flex-col">
                                <p className="text-md">Razorpay Payment Id</p>
                                <p className="text-small text-default-500">{transactionData.successDetails.razorpay_payment_id}</p>
                            </div>
                        }

                        {
                            null != transactionData?.successDetails?.razorpay_order_id &&
                            <div className="flex flex-col">
                                <p className="text-md">Razorpay Order Id</p>
                                <p className="text-small text-default-500">{transactionData.successDetails.razorpay_order_id}</p>
                            </div>
                        }
                    </>
                }

                {
                    null != transactionData?.failureDetails &&
                    <>
                        <hr className="col-span-full"/>

                        <div className="flex flex-col col-span-full">
                            <p className="text-md">Razorpay Failure Details</p>
                            <p className="text-small text-default-500">Code
                                : {transactionData.failureDetails?.code}</p>
                            <p className="text-small text-default-500">Description
                                : {transactionData.failureDetails?.description}</p>
                            <p className="text-small text-default-500">Source
                                : {transactionData.failureDetails?.source}</p>
                        </div>
                    </>
                }
                {transactionData?.paymentStatus === "Pending" && FINANCE_AND_IT_TEAM.includes(user.pranaliAccess) && isEditEnabled &&
                    <div className="col-span-full">
                        <hr className="mb-3"/>
                        <p className="text-md">Update Record :</p>
                        {updateRecordForm()}
                    </div>
                }
                {null != transactionData?.dataUpdates && (FINANCE_AND_IT_TEAM.includes(user.pranaliAccess) ||
                        (null != user?.councilData && user.councilData.designationType === "Core")) &&
                    <div className="col-span-full">
                        <hr className="mb-3"/>
                        <p className="text-md">Record Updates:</p>
                        <p className="text-sm font-light">Updated By: {transactionData.dataUpdates.updatedBy}</p>
                        <p className="text-sm font-light">Comments: {transactionData.dataUpdates.comments}</p>
                        <p className="text-sm font-light">Updated
                            On: {new Date(transactionData.dataUpdates.updatedOn).toLocaleString()}</p>

                    </div>
                }
            </ModalBody>
            <ModalFooter>
                <Button color="danger" variant="light" onPress={props.onClose}>
                    Close
                </Button>

                {transactionData?.paymentStatus === "Pending" && FINANCE_AND_IT_TEAM.includes(user.pranaliAccess) && <>
                    {!isEditEnabled
                        ? <Button size="md" type="button" color="warning" variant="flat"
                                  onClick={() => setIsEditEnabled(true)}>
                            Update
                        </Button>
                        : <Button isLoading={updatedRecordStatus === 'pending'} size="md" type="button" color="warning"
                                  variant="flat" onClick={() => updateOrderStatus(transactionData.transactionId,
                            paymentStatus, updateMessage, user, dispatch, props.records)
                            .then(() => setIsEditEnabled(false))
                            .catch(() => setIsEditEnabled(false))
                        }>
                            Submit
                        </Button>
                    }
                </>}
            </ModalFooter>
        </>
    );
}
