import {Card, CardBody} from "@nextui-org/react";
import {useState} from "react";
import {ChevronLeft, ChevronRight} from "../../Icons/Icons";
import {RxDot, RxDotFilled} from 'react-icons/rx'

export default function Carousel(props) {

    const [currentIndex, setCurrentIndex] = useState(0);

    const prevSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? props.data.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    }

    const nextSlide = () => {
        const isLastSlide = currentIndex === props.data.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    }

    const gotoSlide = (slideIndex) => {
        setCurrentIndex(slideIndex)
    }

    return (
        <Card shadow="sm" className="mt-1 ml-4">
            <CardBody className="grid grid-cols-12 duration-500 items-center">
                <div className="flex justify-start col-span-2 sm:col-span-1">
                    <div className="rounded-full p-2 bg-black/20 text-white cursor-pointer">
                        <ChevronLeft onClick={prevSlide} size={20}/>
                    </div>
                </div>
                <div className="flex flex-col gap-4 col-span-8 sm:col-span-10">
                    <p>{props.data[currentIndex].message}</p>
                    <p className="text-foreground-500 italic"> ~ By {props.data[currentIndex].name}</p>
                </div>
                <div className="flex justify-end col-span-2 sm:col-span-1">
                    <div className="rounded-full p-2 bg-black/20 text-white cursor-pointer">
                        <ChevronRight onClick={nextSlide} size={20}/>
                    </div>
                </div>
                <div className="flex col-span-full top-4 justify-center py-2">
                    {props.data.map((item, index) =>
                        <div key="index" onClick={() => gotoSlide(index)}
                             className="text-foreground-500 text-2xl cursor-pointer">
                            {index === currentIndex ? <RxDotFilled/> : <RxDot/>}

                        </div>
                    )}
                </div>
                {/*{props.data[currentIndex].message}*/}
                {/*<div className="absolute top-[50%] -translate-x-0 translate-y-[50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">*/}
                {/*    <ChevronLeft size={20}/>*/}
                {/*</div>*/}

                {/*<div className="absolute top-[50%] -translate-x-0 translate-y-[50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">*/}
                {/*    <ChevronRight size={20}/>*/}
                {/*</div>*/}
            </CardBody>
        </Card>
    )
}