import {NOMINATION_STATUS_DRAFT, NOMINATION_STATUS_LATE, NOMINATION_STATUS_SUBMITTED} from "./WebPageConstants";
import {Add, Check, Error, Info, Minus} from "../components/Icons/SystemIcons";
import React from "react";

export const OPTIONS_TRANSACTION_TYPES = [
    {
        name: "Debit",
        uid: "Debit",
        description: "Let's see where my money has been deducted",
        icon: <Minus className="text-danger" fill="currentColor" size={28}/>
    },
    {
        name: "Credit",
        uid: "Credit",
        description: "Ooh yeah how much money was added",
        icon: <Add className="text-success" fill="currentColor" size={28}/>
    },
];
export const OPTIONS_NOMINATION_STATUS = [
    {
        name: NOMINATION_STATUS_DRAFT,
        uid: NOMINATION_STATUS_DRAFT,
        icon: <Info className="text-warning" fill="currentColor" size={28}/>
    },
    {
        name: NOMINATION_STATUS_SUBMITTED,
        uid: NOMINATION_STATUS_SUBMITTED,
        icon: <Check className="text-success" fill="currentColor" size={28}/>
    },
    {
        name: NOMINATION_STATUS_LATE,
        uid: NOMINATION_STATUS_LATE,
        icon: <Error className="text-danger" fill="currentColor" size={28}/>
    }
];
export const OPTIONS_NOMINATION_TYPES = [
    {
        name: "Quarter 1",
        uid: "Quarter 1"
    },
    {
        name: "Quarter 2",
        uid: "Quarter 2"
    },
    {
        name: "Quarter 3",
        uid: "Quarter 3"
    },
    {
        name: "Quarter 4",
        uid: "Quarter 4"
    }, {
        name: "Yearly",
        uid: "Yearly"
    },
    {
        name: "On Going",
        uid: "On Going"
    },
    {
        name: "Individual",
        uid: "Individual"
    }
];