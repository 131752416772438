import ApiEndpoint from "./ApiEndpoint";
import {
    setAmbassadorialReports,
    setAmbassadorialReportsStatus, setNewReportStatus, setProjectsForAmbassadorial,
    setProjectsForAmbassadorialStatus, setSelectedReport, setSelectedReportStatus
} from "../components/redux/slice/reportingSlice";
import axios from "axios";
import {throwErrorToast} from "../components/admin/aaras/NewNominationForProjects";
import {toastMessageForSuccess} from "../components/common/ToastMessages";

export function getAmbassadorial(dispatch, user) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setAmbassadorialReportsStatus("pending"));
    dispatch(setAmbassadorialReports([]));

    return axios(apiEndPoint.getAmbassadorialList(user))
        .then(res => {
            if (res.status === 200) {
                dispatch(setAmbassadorialReports(res.data.reverse()));
                dispatch(setAmbassadorialReportsStatus("complete"));
            }
            return res;
        })
        .catch(e => {
            dispatch(setAmbassadorialReportsStatus("complete"));
            throwErrorToast(e);
        });
}

export function getAmbassadorialById(dispatch, user, ambassadorialId, purpose) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setSelectedReportStatus("pending"));
    dispatch(setSelectedReport({
        report: null,
        reportType: "Ambassadorial"
    }));

    return axios(apiEndPoint.getAmbassadorialById(user, ambassadorialId, purpose))
        .then(res => {
            if (res.status === 200) {
                dispatch(setSelectedReport({
                    report: res.data,
                    reportType: "Ambassadorial"
                }));
                dispatch(setSelectedReportStatus("complete"));
            }
            return res;
        })
        .catch(e => {
            dispatch(setSelectedReportStatus("complete"));
            throwErrorToast(e);
        });
}

export function apiCallForNewAmbassadorialReport(dispatch, user, report, navigate) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setNewReportStatus("pending"));

    return axios(apiEndPoint.addAmbassadorial(user, report))
        .then(res => {
            if (res.status === 204) {
                dispatch(setNewReportStatus("complete"));
                toastMessageForSuccess(report.isDraft, "", "Ambassadorial");
                getAmbassadorial(dispatch, user);
                setTimeout(navigate(".."), 2500);
            }
            return res;
        })
        .catch(e => {
            dispatch(setNewReportStatus("complete"));
            throwErrorToast(e);
        });
}

export function apiCallForUpdatingAmbassadorialReport(dispatch, user, report, ambassadorialId, navigate) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setSelectedReportStatus("pending"));

    function clearTaskAndCloseAmbassadorialScreen() {
        dispatch(setProjectsForAmbassadorial([]));
        dispatch(setProjectsForAmbassadorialStatus('idle'));
        navigate("..");
    }

    return axios(apiEndPoint.updateAmbassadorialReport(ambassadorialId, report, user))
        .then(res => {
            if (res.status === 200) {
                dispatch(setSelectedReportStatus("complete"));
                toastMessageForSuccess(report.isDraft, "", "Ambassadorial");
                getAmbassadorial(dispatch, user);
                setTimeout(clearTaskAndCloseAmbassadorialScreen, 2500);
            }
            return res;
        })
        .catch(e => {
            dispatch(setSelectedReportStatus("complete"));
            throwErrorToast(e);
        });
}

export function apiCallForDeletingAmbassadorialReport(dispatch, user, ambassadorialId, records) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setSelectedReportStatus("pending"));

    let recordIndex = records.findIndex(data => data["ambassadorialId"] === ambassadorialId)
    let tempSelectedItem = JSON.parse(JSON.stringify(records[recordIndex]));
    tempSelectedItem.adminData.reportStatus = "Deleted";

    return axios(apiEndPoint.deleteAmbassadorialReport(user, ambassadorialId))
        .then(res => {
            if (res.status === 204) {
                // TO SET DATA ON COPY OF ARRAY LIST AT GIVEN INDEX
                let tempRecords = records.with(recordIndex, tempSelectedItem);
                dispatch(setAmbassadorialReports(tempRecords));
                dispatch(setSelectedReportStatus("complete"));
            }
            return res;
        })
        .catch(e => {
            dispatch(setSelectedReportStatus("complete"));
            throwErrorToast(e);
        });
}