import axios from "axios";
import RotaractLogoCranberry from "../../assets/images/RotaractLogoCranberry.png";
import Rotaract3142 from "../../assets/images/Rotaract3142.png";
import React, {useState} from "react";
import './Login.css';
import {GoogleLogin} from "@react-oauth/google";
import {useDispatch} from "react-redux";
import {setUser, setUserCredentials} from "../redux/slice/userSlice";
import {useNavigate} from "react-router-dom";
import ApiEndpoint from "../../services/ApiEndpoint";
import {toast, ToastContainer} from "react-toastify";
import {toastOptions} from "../../constants/WebPageConstants";
import {throwErrorToast} from "../admin/aaras/NewNominationForProjects";
import {Button} from "@nextui-org/react";


function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    async function authenticateUser(data) {
        setLoading(true);
        await getJwtResponse(data.credential).then(response => {
            if (undefined !== response && null !== response && response.status === 202) {
                let responseData = response.data;
                toast.success(<>
                    <p className="text-medium">Login Successful</p>
                    <p className="text-small">Navigating to another page please wait</p>
                </>, toastOptions)
                dispatch(setUser(responseData));
                dispatch(setUserCredentials(data.credential));
                navigate("/pranali");
            }
        });
        setLoading(false);
    }

    function getJwtResponse(credential) {
        const apiEndPoint = new ApiEndpoint();
        return axios(apiEndPoint.getJwtResponse(credential)).then(res => {
            return res;
        }).catch(e => throwErrorToast(e));
    }

    return (
        <>
            <ToastContainer/>

            <div>
                <div className="flex gap-3 justify-between items-center">
                    <img className="mainLogo" src={RotaractLogoCranberry} alt="Rotaract Logo"/>
                    <h3 className="login">#ekatra3142</h3>

                </div>
                <div className=" custom d-flex justify-content-center flex-column w-100 text-center">
                    <div className="container4 ">
                        <img src={Rotaract3142} alt="Rotaract 3142 Logo"/>
                        <h1>Welcome To Pranali</h1>
                    </div>
                </div>
                <div className="custom1 container text-center">
                    <div className="col align-self-center">
                        {
                            loading ?
                                <div className="mt-2">
                                    <Button type="button" color="primary" variant="bordered" isDisabled
                                            isLoading={loading}>
                                        Authenticating please wait
                                    </Button>
                                </div> :
                                <GoogleLogin useOneTap logo_alignment="center" shape="pill"
                                             onSuccess={(data) => authenticateUser(data)}/>
                        }
                    </div>
                </div>
                {/*<div className="google-btn" onClick={googleLogin}>*/}
                {/*    <div className="google-icon-wrapper">*/}
                {/*        <img className="google-icon" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>*/}
                {/*    </div>*/}
                {/*    <p className="btn-text"><b>Log in with google</b></p>*/}
                {/*</div>*/}
                {/*<Button type="primary" onClick={() => {*/}
                {/*    googleLogout()*/}
                {/*}}>Logout</Button>*/}

            </div>
        </>
    );
}

export default Login;