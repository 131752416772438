import {Zoom} from "react-toastify";

export const COMPLETE_ACCESS_LIST = ["Limited", "Bronze", "Silver", "Gold", "Edge3142", "Finance", "Emerald", "Pink Diamond", "Blue Diamond"];
export const PRANALI_ACCESS_VIEW_FOR_MEMBERSHIP = ["None", "Limited"];
export const ONLY_PRES_SEC = ["Limited", "Bronze"];
export const NON_COUNCIL_ACCESS_LIST = ["None", "Limited", "Bronze"];
export const ONLY_PRES_SEC_AND_IT = ["Limited", "Bronze", "Blue Diamond"];
export const FINANCE_AND_IT_TEAM = ["Finance", "Blue Diamond"];
export const CORE_AND_HIGHER = ["Gold", "Emerald", "Pink Diamond", "Blue Diamond"];
export const PRES_SEC_CORE_AND_HIGHER = ["Limited", "Bronze", "Gold", "Emerald", "Pink Diamond", "Blue Diamond"];
export const MEMBERSHIP_STATUS_FOR_DESIGNATIONS = ["Active", "Guest"];
export const QUARTERLY_NOMINATION_CATEGORIES = ["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4", "Yearly", "On Going", "Flagship"];
export const NOMINATION_STATUS_SUBMITTED = "Submitted";
export const NOMINATION_STATUS_DRAFT = "Draft";
export const NOMINATION_STATUS_LATE = "Late";
export const NOMINATION_TYPE_YEAR_END = ["Yearly", "On Going", "Flagship"];
export const MEMBERSHIP_STATUS_END_OF_ROTARACTION = ["Alumni", "Left", "Terminated"];

export const RAZOR_PAY_TEST_KEY = "rzp_test_SzNdmHcUOgA1jS";
export const RAZOR_PAY_PROD_KEY = "rzp_live_ij2sY2r2RPhB6v";

export const toastOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    newestOnTop: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: "light",
    transition: Zoom
}