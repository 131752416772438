import {toast} from "react-toastify";
import {toastOptions} from "../../constants/WebPageConstants";
import React from "react";

export function toastMessageForSuccess(reportStatus, reportId, reportType) {
    toast.success(<>
        <p className="text-medium">{reportStatus
            ? "Report Saved Successfully"
            : "Report Submitted Successfully"
        }</p>
        <p className="text-small">{reportType + " id allotted. This page will be closing soon"}</p>
    </>, toastOptions);
}