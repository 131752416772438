import {useDispatch, useSelector} from "react-redux";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {
    selectDataForEdit, setDataForEdit
} from "../../redux/slice/aaraSlice";
import {useNavigate} from "react-router-dom";
import ApiEndpoint from "../../../services/ApiEndpoint";
import React, {useEffect, useState} from "react";
import {toastOptions} from "../../../constants/WebPageConstants";
import {toast, ToastContainer} from "react-toastify";
import axios from "axios";
import {Button, Card, CardBody, CardFooter, Input, Progress, Select, SelectItem} from "@nextui-org/react";
import {DD_NOMINATION_FOR_LIST} from "../../../constants/dropdownList";
import {Add} from "../../Icons/SystemIcons";
import {throwErrorToast} from "./NewNominationForProjects";

export default function NewNominationForIndividualAwards() {
    const user = useSelector(selectAllUserData);
    const editData = useSelector(selectDataForEdit);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apiEndPoint = new ApiEndpoint();
    const [isFetchingMembers, setIsFetchingMembers] = useState(false);
    const [fetchedActiveMembers, setFetchedActiveMembers] = useState([]);

    const [nominees, setNominees] = useState(null != editData?.individualNominationList
        ? JSON.parse(JSON.stringify(editData.individualNominationList))
        : [{pranaliId: "", memberName: "", nominationFor: "", driveUrl: "", isPartialSubmit: "", submitStatus: "idle"}]
    );

    const addFields = () => {
        setNominees([...nominees, {
            pranaliId: "",
            memberName: "",
            nominationFor: "",
            driveUrl: "",
            isPartialSubmit: "",
            submitStatus: "idle"
        }])
    }

    const addMemberDetailsToNominations = (i, e) => {
        let newFormValues = [...nominees];
        newFormValues[i].pranaliId = e.target.value;
        newFormValues[i].memberName = fetchedActiveMembers.find(memberData => memberData.pranali_Id === e.target.value).basicData.name;
        setNominees(newFormValues);
    }

    const addNominationFor = (i, e) => {
        let newFormValues = [...nominees];
        newFormValues[i].nominationFor = e.target.value;
        setNominees(newFormValues);
    }

    const addDriveUrl = (i, e) => {
        let newFormValues = JSON.parse(JSON.stringify(nominees));
        newFormValues[i].driveUrl = e.target.value.toString().trim();
        setNominees(newFormValues);
    }

    async function submitNomineeData(isPartialSubmit, index) {

        let data = JSON.parse(JSON.stringify(nominees[index]));

        if (data.driveUrl !== null && data.driveUrl.toString().trim().length > 0) {
            data["isPartialSubmit"] = isPartialSubmit;
            delete data.submitStatus;

            nominees[index].submitStatus = "pending";

            await addOrUpdateNomineeData(data).then(res => {
                if (res.status === 202) {
                    nominees[index].submitStatus = "completed";
                    nominees[index].isPartialSubmit = isPartialSubmit;
                    dispatch(setDataForEdit(res.data))
                    toast.success(<>
                        <p className="text-medium">{data.isPartialSubmit ? "Nomination Saved Successfully" : "Nomination Submitted Successfully"}</p>
                    </>, toastOptions)
                }
            });

            nominees[index].submitStatus = "completed";
        } else {
            toast.warning(<>
                <p className="text-medium">{"Drive URL is empty"}</p>
            </>, toastOptions)
        }
    }

    function cancelForm() {
        dispatch(setDataForEdit(null))
        navigate("/pranali/aara")
    }

    function addOrUpdateNomineeData(data) {
        return axios(apiEndPoint.addOrUpdateIndividualAaraNominations(user, data))
            .then(res => res)
            .catch(e => {
                throwErrorToast(e);
            });
    }


    function getMembers() {
        setIsFetchingMembers(true);
        axios(apiEndPoint.getMembersList("Active", user)).then(res => {
            setIsFetchingMembers(false);
            setFetchedActiveMembers(res.data);
        }).catch(e => {
            throwErrorToast(e);
            setIsFetchingMembers(false);
        });
    }

    useEffect(() => {
        getMembers()
    }, []);

    return (
        <>
            <ToastContainer/>
            <Card>
                <CardBody>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Individual AARA Nomination :
                        {/*<span className="text-sm text-gray-500"> {editData.nominationId}</span>*/}
                    </h2>

                    {isFetchingMembers &&
                        <div className="mt-10 w-full flex flex-col gap-4">
                            <Progress
                                size="sm"
                                isIndeterminate
                                aria-label="Loading..."
                            />
                            <p className="text-sm font-regular leading-7 text-gray-900">Please wait while we fetch
                                your Home Club Members for You 😘</p>
                        </div>
                    }

                    {(fetchedActiveMembers && fetchedActiveMembers.length > 0) &&
                        <>
                            <div className="mt-10 w-full flex flex-col gap-4">
                                <p className="text-sm font-regular leading-7 text-gray-900">Select Members and
                                    Nomination Category</p>
                                {nominees.map((memberData, index) => (
                                    <div className="grid grid-cols-2 sm:grid-cols-12 gap-4 items-center">
                                        <Select
                                            isDisabled={memberData.isPartialSubmit === false}
                                            isRequired
                                            label="Pranali id"
                                            defaultSelectedKeys={memberData.pranaliId ? [memberData.pranaliId] : []}
                                            variant="underlined"
                                            className="max-w-md col-span-2 sm:col-span-2"
                                            onChange={e => addMemberDetailsToNominations(index, e)}
                                        >
                                            {fetchedActiveMembers.map(data =>
                                                <SelectItem key={data.pranali_Id} textValue={data.pranali_Id}>
                                                    <div className="flex gap-2 items-center">
                                                        <div className="flex flex-col">
                                                            <span className="text-small">{data.basicData.name}</span>
                                                            <span
                                                                className="text-tiny text-default-400">id {data.pranali_Id}</span>
                                                        </div>
                                                    </div>
                                                </SelectItem>
                                            )}
                                        </Select>
                                        <Input
                                            isDisabled
                                            type="text"
                                            variant="underlined"
                                            label="Member Name"
                                            value={memberData.memberName}
                                            className="max-w-md col-span-2 sm:col-span-3"
                                        />
                                        <Select
                                            isDisabled={memberData.isPartialSubmit === false}
                                            isRequired
                                            label="Nomination For"
                                            variant="underlined"
                                            className="max-w-md col-span-2 sm:col-span-3"
                                            defaultSelectedKeys={memberData.nominationFor ? [memberData.nominationFor] : []}
                                            onChange={e => addNominationFor(index, e)}
                                        >
                                            {DD_NOMINATION_FOR_LIST.map(data => (
                                                <SelectItem key={data.value} value={data.value}>
                                                    {data.label}
                                                </SelectItem>
                                            ))}
                                        </Select>

                                        <Input
                                            isDisabled={memberData.isPartialSubmit === false}
                                            type="text"
                                            variant="underlined"
                                            label="Drive URL"
                                            value={memberData.driveUrl}
                                            className="max-w-md col-span-1 sm:col-span-2"
                                            onChange={e => addDriveUrl(index, e)}
                                        />

                                        <Button className="max-w-sm" color="primary" variant="bordered"
                                                isLoading={memberData.submitStatus === "pending"}
                                                isDisabled={memberData.isPartialSubmit === false}
                                                onClick={() => submitNomineeData(true, index)}>
                                            Draft
                                        </Button>
                                        <Button className="max-w-sm" color="success" variant="bordered"
                                                isLoading={memberData.submitStatus === "pending"}
                                                isDisabled={memberData.isPartialSubmit === false}
                                                onClick={() => submitNomineeData(false, index)}>
                                            Submit
                                        </Button>
                                    </div>
                                ))}
                            </div>
                            <div className="mt-10 w-full flex justify-center">
                                <Button className="max-w-sm" color="primary" variant="bordered" startContent={
                                    <Add fill="currentColor" size={28}/>
                                } onClick={addFields}>
                                    Add more
                                </Button>
                            </div>
                        </>
                    }
                </CardBody>
                <CardFooter className="flex flex-wrap gap-4 justify-end">
                    <Button type="button" color="default" variant="faded" onClick={() => cancelForm()}>
                        Cancel
                    </Button>
                </CardFooter>
            </Card>
        </>
    );
}
